import React from 'react';
import tabelasPDF from '../pdfs/tabelas'
import { CSVLink } from "react-csv";
import './styles.css'

function ButtonsFile(props) {
    function translate(str){
        if(str == "queue") return "Fila"
        if(str == "announcement") return "Anuncio"
        if(str == "ringgroup") return "Grupo de Toque"
        if(str == "account") return "Ramal"
        if(str == "ivr") return "URA"
        else return str
      }
    

    const treatCSVdata = props.data.map((row) =>{
        let arrayRow = []
        for (let i = 0; i < props.headers.length; i++) {
            let translateKey = ""
            translateKey = props.headers[i] == "Ramal" ? "extension" : props.headers[i] == "Nome" ? "fullname" : props.headers[i] == "Setor" ? "fullname" 
            : props.headers[i] == "DiD" ? "did_pattern_match" : props.headers[i] == "Tipo" ? "destination_type" : props.headers[i] == "Destino" ?  "destination_type" 
            : props.headers[i]
            
            let cell = row[translateKey] ? row[translateKey] : ''
            if((props.headers[i] === 'Nome' || props.headers[i] === 'Setor') && cell){
                let arrayName = cell.split("-")
                if(arrayName.length > 1){
                    if(props.headers[i] === 'Nome'){
                        cell = arrayName[0]
                    }if(props.headers[i] === 'Setor'){
                        cell = arrayName[1]
                    }
                }
            }

            if(props.headers[i] === 'Tipo' && cell){
                cell = translate(cell)
            }

            if(props.headers[i] === 'Destino' && cell){
                cell =   row[translateKey] === 'account' ? row.account 
                : row[translateKey] === 'queue' ? row.queue 
                : row[translateKey] === 'ringgroup' ? row.ringgroup 
                : row[translateKey] === 'announcement' ? row.announcement 
                : row[translateKey] === 'external_number' ? row.external_number 
                : row.ivr
            }
            if(translateKey=== "extension" && cell) cell = cell.replace(/_/g, ' ')


            arrayRow.push(cell)
        }
        return arrayRow 
    })
    

    return (
        <div className='buttons-files-div'>
            <div className='button-file-div'>
                <button className='button-file-pdf' onClick={() => {
                    tabelasPDF(props.title,props.headers,props.data)
                }}>
                    Download PDF
                </button>
            </div>
            <div className='button-file-div'>
                <button className='button-file-excel'
                    onClick={() => {
                        document.getElementById('download-csv-link').click();
                    }}>
                    Download CSV
                </button>
                <CSVLink data={treatCSVdata} headers={props.headers} filename={props.title + ".csv"} className="hidden" target="_blank" id="download-csv-link"/>

            </div>
        </div>
    
    );
}

export default ButtonsFile;