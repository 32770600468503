import { useState, useContext } from 'react'
import { motion } from "framer-motion";
import './styles.css'
import MailPassword from './MailPassword'
import Account from './Account';
import { UserContext } from '../../contexts/UserContext'

function Login() {

  const [page, setPage] = useState(0)
  const [formData, setFormData] = useState({
    conta: '',
    email: '',
    senha: ''
  })
  const [x, setX] = useState(0); 

  const componentList = [
    <Account 
    page={page}
    setPage={setPage}
    formData={formData}
    setFormData={setFormData}
    x={x}
    setX={setX}
    />,
    <MailPassword 
    page={page}
    setPage={setPage}
    formData={formData}
    setFormData={setFormData}
    x={x}
    setX={setX}
    />
  ]

  return (
    <div className='login-container'>
      <div>{componentList[page]}</div>
    </div>
  )
}

export default Login