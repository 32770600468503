import AppRoutes from './routes'
import { BrowserRouter } from 'react-router-dom'
import './global.css'
import { UserProvider } from './contexts/UserContext';

function App() {
  

  return (
    <div>
      <BrowserRouter>
        <UserProvider>
          <AppRoutes />
        </UserProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
