import React from 'react';
import {useState, useEffect} from 'react';
import QueueDetails from './queue/QueueDetails';
import AgentResume from './member/AgentResume';
import AgentDetails from './member/AgentDetails';
function DetailsView(props) {

    const [seletedView, setSelectedView] = useState('queue');
    const changeView = (view) => setSelectedView(view);
    const [agent, setAgent] = useState({});

    function setMemberOnView(member,opt)  {
        setAgent(member);
        changeView(opt);
        let queue = props.queue.extension
        const newAgent ={
            agent :  props.selectedUcm.extensions.find((extension) => {
                return extension.extension == member
            }),
            stats : props.queue.agentsStats?.agent_statistics?.filter((agentStats) => agentStats.agent === member),
            details : props.queue.agentsDetails?.details.filter((agentStats) => agentStats.agent === member),
            queue : queue,
        }
        setAgent(newAgent);
    }
    const views = {
        queue: <QueueDetails queue={props.queue} changeView={changeView} changeScreen={props.changeScreen} setMemberOnView={setMemberOnView} selectedUcm={props.selectedUcm} />,
        resume: <AgentResume agent={agent} queue={props.queue} changeView={changeView} changeScreen={props.changeScreen} />,    
        details: <AgentDetails agent={agent} queue={props.queue} changeView={changeView} changeScreen={props.changeScreen} />
    }
    return (
        <div >
            {views[seletedView]}
        </div>
    );
}

export default DetailsView;