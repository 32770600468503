import React from 'react';
import {useEffect,useState} from 'react';
import Api from '../../services/api';
import {useParams} from 'react-router-dom'
import Pagination from '../../components/Pagination';
import './style.css';
import ButtonsFile from '../../components/ButtonsFile/ButtonsFile';

const Ramais = () => {
    const {nomePref,nomelista} = useParams();
    const [ramais,setRamais] = useState([]);
    const [ramaisOnShow,setRamaisOnShow] = useState([]);
    const [picture,setPicture] = useState('');
    const [nome,setNome] = useState('');
    const [error,setError] = useState('');
    const [loading,setLoading] = useState(true);
    /*
                          postsPerPage={postsPerPage} 
                            totalPosts={filteredExtensions.length} 
                            currentPage={currentPage} 
                            setCurrentPage={setCurrentPage}
                        />
      
    */
    const [postsPerPage, setPostsPerPage] = useState(10) //setting number of posts per page
    const [currentPage, setCurrentPage] = useState(1)
    const [order, setOrder] = useState(1)
 

    function getList(){
        Api.post("/myList/",{pref:nomePref,nome:nomelista}).then(res => {
            if(res.data.foto)setPicture(res.data.foto);
            if(res.data.link.ramais)setRamais(res.data.link.ramais);
            if(res.data.error)alert(res.data.error);
        }).catch(err => console.log(err));
        setLoading(false);  
    }
    useEffect( () => {
        getList();
    },[])
    

    useEffect( () => {
        const lastPostIndex = currentPage * postsPerPage //currentPAge = 2 e postPerPage = 10 ---> 20
        const firstPostIndex = lastPostIndex - postsPerPage //lastPostIndex = 20 e postPerPage = 10 ---> 10
        const currentPosts = ramais.slice(firstPostIndex, lastPostIndex)
        setRamaisOnShow(currentPosts);
    },[ramais,currentPage,postsPerPage])


    const search = (e) => {
        const lowerSearch = e.target.value.toLowerCase().trim();
        const filteredExtensions = ramais.filter((extension) => lowerSearch === '' ? extension :extension.extension?.toLowerCase().includes(lowerSearch) || extension.fullname?.toLowerCase().includes(lowerSearch) )
        setRamaisOnShow(filteredExtensions);
    }

    return (
        <div className='ramais'>
            <div className='ramais-header'>
                <div className='ramais-header-picture'>
                    <img src={picture} alt='Foto da Organização' className='ramais-header-picture-img'/>
                </div>
                <div className='ramais-header-org-title'>
                    <h1>{nomePref.replace(/_/g,' ')}</h1>
                </div>
            </div>
            <div className='ramais-body'>
                <div className='ramais-body-title'>
                    <h1>{nomelista}</h1>
                </div>
                <div className='ramais-body-table'>
                    <div className='ramais-body-table-inputs'>
                        <input type='text' placeholder='Pesquisar' className='search ramais-body-table-inputs-search' style={{width:"100%"}} onChange={search}/>
                        <div>
                            <label htmlFor="">Mostrando: </label>
                            <select className='ramais-body-table-inputs-select' onChange={e => setPostsPerPage(e.target.value)}>
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='30'>30</option>
                                <option value='40'>40</option>
                                <option value='50'>50</option>
                            </select>        
                        </div>                
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Setor</th>
                                <th>Telefone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ramaisOnShow.map((ramal,i) => {
                                let evenOrOdd = i % 2 === 0 ? 'even' : 'odd';
                                return(                                
                                <tr key={ramal.extension} className={evenOrOdd}>
                                    <td>{ramal.name}</td>
                                    <td>{ramal.setor}</td>
                                    <td>{ramal.extension}</td>
                                </tr>
                            )})}
                        </tbody>
                    </table>
                                
                        <Pagination 
                            postsPerPage={postsPerPage} 
                            totalPosts={ramais.length}
                            currentPage={currentPage} 
                            setCurrentPage={setCurrentPage}
                        />

                    
                    </div>
            </div>
            <ButtonsFile 
                headers={['Nome','Setor','Ramal'
                ]}
                data={ramais}
                
            />   
            <br/>
            <br/>
        </div>
    );
};

export default Ramais;