import React from 'react';
import { useContext, createContext, useEffect, useState } from 'react'
import { UserContext } from './UserContext'
import api from '../services/api';

const UCMSContext = createContext();

const UCMSProvider = ({children}) => {
    const context = useContext(UserContext);
    const [ucms, setUcms] = useState([]);
    const [ucmSelected, setUcmSelected] = useState(null);
    

    
    useEffect(()=>{
      if(context.token && context.user){
        getNewUcms()
      }
    },[ context.token])

    async function getNewUcms(){
      let newUCMS =[]       
        for (let i = 0; i < context.user.ucm.length; i++) {
          await api.post("/ucms/byId", {id: context.user.ucm[i]}).then(resp=>{
            newUCMS.push(resp.data)
            setUcms(newUCMS)
          })
        }
    }
    return (
      <UCMSContext.Provider value={{ ucms,  ucmSelected, setUcmSelected }}>
        {children}
      </UCMSContext.Provider>
    );
}

export { UCMSContext, UCMSProvider };