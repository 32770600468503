import { styled } from "@mui/material";

export const PainelContainer = styled('div')`
  margin-left: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
export const UcmName = styled('h1')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  margin-left:5%;
  margin-bottom: 4rem;

`
export const Manager = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
  margin-bottom: 2rem;
  `
export const TextLink = styled('a')`
  font-size: 1.8rem;
  margin-right: 2rem;
  cursor: pointer;
  color: #000;
  &:hover{
    color: #000;
    text-decoration: underline;
  }
`
