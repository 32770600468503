import React, { useState, useContext, useEffect } from 'react';
import * as C from './styles';
import { FaBars, FaTimes, FaThList, FaPager, FaUserAlt, FaUserFriends} from 'react-icons/fa';
import { UCMSContext } from '../../contexts/UCMSContext';
import './index.css'

const SideBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const context = useContext(UCMSContext);
  const [ucmsOnview, setUcmsOnview] = useState([]);
  
  const toggle = () => setIsOpen(!isOpen);
  const searchUcm = (e) => {
    const searching = e.target.value;
    setSearch(searching);
    const newUcms = context.ucms.filter((ucm) => {
      if(!ucm.cidade) console.log(ucm)
      return ucm.cidade?.toLowerCase().includes(search?.toLowerCase());
    });
    setUcmsOnview(newUcms);

  }

  useEffect(()=>{
    if(context&&context.ucms){
      setUcmsOnview(context.ucms)
      if(search){
        searchUcm({target:{value:search}})
      }
    }
  },[context.ucms])

  return (
    <C.Container isOpen={isOpen}>
      <div className="push"></div>
      <C.SideBar isOpen={isOpen}>
        <div className='top-section'>
          <strong>VoxCount</strong>
          <div className="bars">
            {isOpen ? <FaTimes onClick={toggle} size={35} /> : <FaBars onClick={toggle} size={35} />}
          </div>
        </div>
        {
          isOpen && context?.ucms.length> 3 && <div className="search-box-ucms">
            <input className="ucms-search-input" type="text" placeholder="Pesquisar UCM" onChange={searchUcm} value={search} />
          </div>

        }
        <ul>
          {isOpen && ucmsOnview?.map((ucm, index) => {
            return <C.UCMSLink key={index} 
            onClick={(e)=>{
              props.selectedUcm(ucm)
              props.changeScreen("infos")
              context.setUcmSelected(ucm)
              setIsOpen(false)
            }}
            >
              <li 
              style={{marginLeft:'-12px' }}
              >{ucm.cidade} {`\n`}
                {ucm.local}
              </li>
            </C.UCMSLink>
            })}
        </ul>
      </C.SideBar>
        <main>{props.children}</main>
    </C.Container>
  )
}

export default SideBar;