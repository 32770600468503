import React, { useState, useMemo } from 'react'
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import ButtonsFile from '../../../components/ButtonsFile/ButtonsFile'
import Pagination from '../../../components/Pagination'
import '../queues/styles.css'

const Inbounds = (props) => {
  //console.log(props.selectedUcm.inbounds)
  
  const [order, setOrder] = useState(1)
  const [columnOrder, setColumnOrder] = useState('did_pattern_match')
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(5)

  const evenOdd = (index) => {
    if (index % 2 === 0) {
        return 'even'
    } else {
        return 'odd'
    }
  }

  let filteredInbounds = useMemo (() => {
    const lowerSearch = search.toLowerCase().trim()
    return props.selectedUcm.inbounds.filter((inbound) => lowerSearch === '' ? inbound : inbound.did_pattern_match.toLowerCase().includes(lowerSearch) || translate(inbound.destination_type).toLowerCase().includes(lowerSearch))
    
  }, [search, props.selectedUcm.inbounds])

  function translate(str){
    if(str == "queue") return "Fila"
    if(str == "announcement") return "Anuncio"
    if(str == "ringgroup") return "Grupo de Toque"
    if(str == "account") return "Ramal"
    if(str == "ivr") return "URA"
    else return str
  }

  const lastPostIndex = currentPage * postsPerPage //currentPAge = 2 e postPerPage = 10 ---> 20
  const firstPostIndex = lastPostIndex - postsPerPage //lastPostIndex = 20 e postPerPage = 10 ---> 10
  const currentPosts = filteredInbounds.slice(firstPostIndex, lastPostIndex)

  const renderInbounds = () => {
		return currentPosts?.map((inbound, index) => {
			return (
				<tr key={index} className={ evenOdd(index) }>
          <td>{inbound.did_pattern_match.replace('_', '')}</td>
          <td>{translate(inbound.destination_type)}</td>
          <td>{
            inbound.destination_type === 'account' ? inbound.account 
            : inbound.destination_type === 'queue' ? inbound.queue 
            : inbound.destination_type === 'ringgroup' ? inbound.ringgroup 
            : inbound.destination_type === 'announcement' ? inbound.announcement 
            : inbound.destination_type === 'external_number' ? inbound.external_number 
            : inbound.ivr}
          </td>
          {/* esperando a implementação do 'Nome do Destino que vem da API  */}
          {/* <td></td> */}
      </tr>
			);
		});
	};

  const handleOrder = (fieldName) => {
    setOrder(-order)
    setColumnOrder(fieldName)
  }

  if (!columnOrder) return filteredInbounds;
  
  filteredInbounds = filteredInbounds.sort((a, b) => {
    return a[columnOrder] < b[columnOrder] ? -order : order
  })


  return (
    <div className='container'>
      <h1>
        Rotas de Entrada - {props.selectedUcm && props.selectedUcm.cidade + (props.selectedUcm.local ?`( ${props.selectedUcm.local} )`: "")}
      </h1>
      <br />
      <div className='inputs-div'>
        <input 
          id="#search-queues" 
          type="text" 
          placeholder="Pesquisar" 
          value={search}
          onChange={((e) => setSearch(e.target.value))}
          className="search" 
        />
        <label>
          Mostrando: 
        </label>
        <select className='input-selection' onChange={e => setPostsPerPage(e.target.value)}>
          <option value='10'>10</option>
          <option value='20'>20</option>
          <option value='30'>30</option>
          <option value='40'>40</option>
          <option value='50'>50</option>
        </select>
      </div>
      <div style={{overflowX: 'auto'}}>
        <table className='table table-striped' aria-labelledby="tabelLabel">
          <thead className='thead-dark'>
              <tr>
                <th 
                  className='filter'
                  onClick={() => handleOrder('did_pattern_match')}
                > 
                  DiD {order === 1 ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />} 
                </th>
                <th>Tipo</th>
                <th>Destino</th>
                {/* esperando a implementação do 'Nome do Destino que vem da API */}
              {/*  <th onClick={() => handleOrder('destination_type')}>Nome do Destino</th> */}
              </tr>
          </thead>
          <tbody className='thead-light'>
            {renderInbounds()}
          </tbody>
        </table>
        <Pagination 
          postsPerPage={postsPerPage} 
          totalPosts={filteredInbounds.length} 
          currentPage={currentPage} 
          setCurrentPage={setCurrentPage}
        />
      </div>
      
      <ButtonsFile
        title = 'Rotas de Entrada'
        headers={['DiD', 'Tipo', 'Destino']}
        data={filteredInbounds}  
      />
      <div className='back-button'>
        <button onClick={() => props.changeScreen('infos')}>Voltar</button>
      </div>
    </div>
  )
}

export default Inbounds