import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";

export const Container = styled('div')(({isOpen}) => (
  `
  display: flex;
  
  

  .push {
    margin-left: ${isOpen ? '9.5rem' : 0};
    transition: all .4s;

    @media(max-width: 600px) {
      margin-left: 3.5rem;
    }
  }

  main {
    width: 100%;
    padding: 20px;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin-top: 3rem;
    margin-left: 2rem;
    list-style-type: none;
  }
`
))

export const SideBar = styled('nav')(({isOpen}) => (
  `
    position: fixed;
    z-index: 1000;
    background: rgb(0, 30, 60);
    // linear-gradient(90deg,#264e8e,#7487ce);
   /*  background: #1a237e; */
    color: #ffffff;
    min-height: 100vh;
    width: ${isOpen ? '290px' : '60px'};
    transition: all .4s;
    top:0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    
    ::-webkit-scrollbar {
      width: 1px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    

    .top-section {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2rem;
      background: 232f34#;
      // text-shadow: .2rem .2rem #333;

      strong {
        font-size: 1.7rem;
        font-weigth: 700;
        letter-spacing: 2px;
        display: ${isOpen ? 'block' : 'none'}
        
      }

      h2, .bars{
        font-size: 1.2rem;
      }


      .bars {
        display: flex;
        margin-left: ${isOpen ? '40px' : '0px'};
        cursor: pointer;
        transition: all .5s;
        
        

        &.bars {
          transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
          
        }
      }

    }

    /* .active {
      background: lightskyblue;
      color: #000000;
      
    } */
    
  `
))

export const UCMSLink = styled(NavLink)(({isOpen}) => (
  `
  text-decoration: none;
  height: 5rem;
  padding-inline: ${isOpen ? '3rem' : '15px' } ;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 1.2rem;

  &:hover {
    background: linear-gradient(90deg, #61605F, #DAD8D6);
    border-left: 5px solid rgb(145, 158, 171);
    margin-left: -2rem;
    padding-left: 3rem;
    padding-block: 2.5rem;
  }

  .link-text {
    font-weight: 300;
    display: ${isOpen ? 'block' : 'none'}
  }

`
))