import { motion } from "framer-motion";
import './styles.css'

function Account({page, setPage, formData, setFormData, x, setX }) {

  return (

      <motion.div 
      className='login-form'
      initial={{ x: x }}
      transition={{ duration: 1 }}
      animate={{ x: 0 }}>
        <div className='login-header'>
          <img src="/logo-branca.png" alt="logo" />
        </div>
        <div className='form-content'>
          <form>
            <label name='conta'>Conta</label>
            <input 
            type='text' 
            name='conta'
            value={formData.conta}
            onChange={(e) =>
              setFormData({ ...formData, conta: e.target.value })
            }></input>
            <button 
            className='next-btn'
            onClick={() => {
              setPage(page + 1);
              setX(1000);
            }}>
              Avançar</button>
          </form>
        </div>
      </motion.div>

  )
}

export default Account