import React,{useState} from 'react'
import Management from './MyLists/MyLists';
import EditRamais from './EditRamais/index';
import './style.css';


function ManagerScreen(props) {
    const [screenSelected, setScreenSelected] = useState('manager');
    const [selectedList, setSelectedList] = useState(null);

    const changeScreen = (screen,list) => {
        setScreenSelected(screen);
        setSelectedList(list);
    }

    
    const screens = {
        manager: <Management propsChangesScreen={props.changeScreen} changeScreen={changeScreen} />,
        editRamais: <EditRamais list={selectedList}changeScreen={changeScreen} />,
    }

    return (
        <div className='main-manager-div'>
            <h1>Gerenciamento</h1>
            <br/>
            <hr/>
            <br/>
            {screens[screenSelected]}
        </div>
    );
}

export default ManagerScreen;