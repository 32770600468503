import React, { useState } from 'react'
import SideBar from '../../components/Sidebar'
import PainelInfos from './PainelInfos/PainelInfos'
import QueuesOnView from './queues/index'
import Extensions from './Extensions/index'
import InboundsOnView from './Inbounds/index'
import OutboundsOnView from './Outbounds/index'
import Management from './Management/index'
import {useContext,useEffect} from 'react'
import {UserContext} from '../../contexts/UserContext'
function Dashboard() {
  const [screenSelected, setScreenSelected] = useState('default');
  const [selectedUcm, setSelectedUcm] = useState(null);

  const context = useContext(UserContext);

  const {pref,user} = context;
  const changeScreen = (screen) => {
    setScreenSelected(screen);
}
const screens = {
   
    infos: <PainelInfos changeScreen={changeScreen}  selectedUcm={selectedUcm} />,
    queues: <QueuesOnView changeScreen={changeScreen}  selectedUcm={selectedUcm} />,
    extensions: <Extensions changeScreen={changeScreen} selectedUcm={selectedUcm} />,
    inbounds: <InboundsOnView changeScreen={changeScreen} selectedUcm={selectedUcm} />,
    outbounds: <OutboundsOnView changeScreen={changeScreen} selectedUcm={selectedUcm} />,
    management: <Management changeScreen={changeScreen} selectedUcm={selectedUcm} />,
}

// useEffect(() => {
//   drawCanvasFromImage();
// },[])
  return (
    <div>
      <SideBar 
      selectedUcm={setSelectedUcm}
      changeScreen={changeScreen}
      />
    <img id='logo-prefeitura' src={pref.foto?pref.foto:null } alt="logo-prefeitura" />
      {screens[screenSelected]}
    </div>
  )
}

export default Dashboard