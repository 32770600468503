
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

export default function tabelasPDF(title,keyHeaders, data) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  function translate(str){
    if(str == "queue") return "Fila"
    if(str == "announcement") return "Anuncio"
    if(str == "ringgroup") return "Grupo de Toque"
    if(str == "account") return "Ramal"
    if(str == "ivr") return "URA"
    else return str
  }

    const table = {
        headerRows: 1,
        widths:keyHeaders.map((k,i)=>{
            return '*';
        }),
        body: [
            keyHeaders.map((key) => ({ 
                text: key,
                style: 'tableHeader',
                })),
            ...data.map((row) =>{
                let arrayRow = []
                for (let i = 0; i < keyHeaders.length; i++) {
                    let translateKey = ""
                    translateKey = keyHeaders[i] == "Ramal" ? "extension" : keyHeaders[i] == "Nome" ? "fullname" : keyHeaders[i] == "Setor" ? "fullname" 
                    : keyHeaders[i] == "DiD" ? "did_pattern_match" : keyHeaders[i] == "Tipo" ? "destination_type" : keyHeaders[i] == "Destino" ?  "destination_type" 
                    : keyHeaders[i]
                    let cell = row[translateKey] ? row[translateKey] : row[keyHeaders[i]] ? row[keyHeaders[i]] : ''
                    if((keyHeaders[i] === 'Nome' || keyHeaders[i] === 'Setor') && cell){
                        let arrayName = cell.split("-")
                        if(arrayName.length > 1){
                            if(keyHeaders[i] === 'Nome'){
                                cell = arrayName[0]
                            }if(keyHeaders[i] === 'Setor'){
                                cell = arrayName[1]
                            }
                        }
                    }
                    if(keyHeaders[i] === 'Tipo' && cell){
                        cell = translate(cell)
                    }
        
                    if(keyHeaders[i] === 'Destino' && cell){
                        cell =   row[translateKey] === 'account' ? row.account 
                        : row[translateKey] === 'queue' ? row.queue 
                        : row[translateKey] === 'ringgroup' ? row.ringgroup 
                        : row[translateKey] === 'announcement' ? row.announcement 
                        : row[translateKey] === 'external_number' ? row.external_number 
                        : row.ivr
                    }
                    if(translateKey=== "extension" && cell) cell = cell.replace(/_/g, ' ')

                    arrayRow.push(cell)
                }
                return arrayRow
            })
            
        ]
    }
    const docDefinition = {
        content: [
            { text: title, style: 'header' },
            {
                style: 'tableExample',
                table
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            }
        }
    };
    pdfMake.createPdf(docDefinition).open();
    

}