import Card from '../Card';
import * as C from '../styles';
import {useContext} from 'react';
import{UserContext} from '../../../contexts/UserContext';

function PainelInfos(props) {
  const {user ,pref } = useContext(UserContext);
  return (
        <div className='painel-infos'>
          <C.UcmName>
            UCM {props.selectedUcm.cidade}  {props.selectedUcm.local? `(${props.selectedUcm.local})` : ''}
          </C.UcmName>
          
          <C.PainelContainer>            
            <Card handleClick={e=>props.changeScreen('inbounds')} title={'Rotas de Entrada'} info={props.selectedUcm.inbounds?.length}/>
            <Card handleClick={e=>props.changeScreen('outbounds')} title={'Rotas de Saída'} info={props.selectedUcm.outbounds?.length}/>
            <Card handleClick={e=>props.changeScreen('extensions')} title={'Ramais'} info={props.selectedUcm.extensions?.length}/>
            <Card handleClick={e=>props.changeScreen('queues')} title={'Total de filas'} info={props.selectedUcm.queues?.length} />
            {
              (user.manager || user.admin) && 
                <Card handleClick={e=>props.changeScreen('management')} title={'Páginas de Ramais'} info={ pref.services.voxcount.linkDeRamais?.length}/>
            }
          </C.PainelContainer>  
        </div>
      
    )
}

export default PainelInfos