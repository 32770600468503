import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto'

function ChamadasBar(props) {
    const backgroundColorAtendidas = 'rgba(54, 162, 235, 0.2)';
    const backgroundColorAbandonadas = 'rgba(255, 99, 132, 0.2)';
    const borderColorAtendidas = 'rgba(54, 162, 235, 1)';
    const borderColorAbandonadas = 'rgba(255, 99, 132, 1)';


    return (
        <>
            <Bar
                id={props.id}
                data={{
                    labels: props.labels,
                    datasets: [{
                        label: 'Chamadas atendidas',
                        data: props.stats.map((item) => item.atendidas),
                        backgroundColor: backgroundColorAtendidas,
                        borderColor: borderColorAtendidas,
                        borderWidth: 1,
                    },
                    {
                        label: 'Chamadas abandonadas',
                        data: props.stats.map((item) => item.abandonadas),
                        backgroundColor: backgroundColorAbandonadas,
                        borderColor: borderColorAbandonadas,
                        borderWidth: 1,
                    }],
                }}
                responsive={true}
                options={{
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                }}
                height={400}
            />
        </>
    );
}

export default ChamadasBar;