import React from 'react';
import {BiMessageSquareAdd} from 'react-icons/bi';
import {useState,useEffect} from 'react';

/**
 * 
{/*             
            <table className='ramais-to-add-table'>
                <thead>
                    <tr>
                        <th>Ramal</th>
                        <th>Nome</th>
                        <th>Setor</th>
                        <th>Adidicionar</th>
                        <th>
                            <input type='checkbox' checked={props.selectedToAdd.length === props.ucms?.find(ucm => ucm._id === props.ucmSelected)?.extensions?.length} onChange={e => {
                                if(props.ucmSelected){
                                    if(e.target.checked){
                                        let ucm = props.ucms?.find(ucm => ucm._id === props.ucmSelected);
                                        let ramaisToAdd =ucm?.extensions?.map(ext => {
                                            return {
                                                extension:ext.extension,
                                                fullname:ext.fullname,
                                                name:ext.fullname?.split('-')[0]?ext.fullname?.split('-')[0]:'',
                                                setor:ext.fullname?.split('-')[1]? ext.fullname?.split('-')[1] : '',          
                                                ucm: ucm._id,
                                                local: ucm.local + ' - ' + ucm.cidade
                                            }
                                        });
                                        props.setSelectedToAdd(ramaisToAdd);

                                    } else {
                                        props.setSelectedToAdd([]);
                                    }
                                }
                            }}/>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.ucmSelected && props.ucms?.map((ucm,index) => {
                            if(ucm._id === props.ucmSelected){
                                return (
                                    ucm?.extensions?.map((ramal,index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{ramal.extension}</td>
                                                <td>{ramal.fullname}</td>
                                                <td>{ramal.fullname}</td>
                                                <td><BiMessageSquareAdd onClick={e => {
                                                    e.preventDefault();
                                                    let ramalToAdd = {
                                                        ...ramal,
                                                        ucm:ucm._id,
                                                        local:ucm.local + ' - ' + ucm.cidade,
                                                    }
                                                    props.addRamal(ramalToAdd);
                                                }}/></td>
                                                <td>
                                                    <input type='checkbox' checked={props.selectedToAdd.find(r => r.extension === ramal.extension && r.ucm === ucm._id)} onChange={e => {
                                                        if(e.target.checked){
                                                            ramal.ucm = ucm._id;
                                                            ramal.local = ucm.local + ' - ' + ucm.cidade;
                                                            props.setSelectedToAdd([...selectedToAdd,ramal]);
                                                        } else {
                                                            let newSelected = [...selectedToAdd];
                                                            newSelected = newSelected.map(r => {
                                                                if(r.extension === ramal.extension && r.ucm === ucm._id){
                                                                    return null;
                                                                } else return r;
                                                            });
                                                            newSelected = newSelected.filter(r => r !== null);
                                                            props.setSelectedToAdd(newSelected);
                                                        }
                                                    }}/>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                        })
                    }
                </tbody>
            </table> */
const AddRamaisTable = (props) => {
    const [ramaisOnView, setRamaisOnView] = useState([]);

    function setOnView(){
        if(props.ucmSelected && props.ucms){
            let ucm ={...props.ucms.find(ucm => ucm._id === props.ucmSelected)}
            let ramais = ucm.extensions ? ucm.extensions.map(ramal => {
                return {
                    extension:ramal.extension,
                    ucm:ucm._id,
                    fullname:ramal.fullname? ramal.fullname : "Sem nome",
                    local:ramal.local,
                    name:ramal.fullname?.split('-')[0]?ramal.fullname?.split('-')[0]:ramal.fullname,
                    setor:ramal.fullname?.split('-')[1]? ramal.fullname?.split('-')[1] :ramal.fullname
                }
            }):[];
            setRamaisOnView(ramais);
        }
    }

    const searchRamais = (e) => {
        if(props.ucmSelected && props.ucms){
            if(e.target.value !== ''){
                let ucm ={...props.ucms.find(ucm => ucm._id === props.ucmSelected)}
                let ramais = ucm.extensions?.map(ramal => {
                    return {
                        extension:ramal.extension,
                        ucm:ucm._id,
                        fullname:ramal.fullname? ramal.fullname : "Sem nome",
                        local:ramal.local,
                        name:ramal.fullname?.split('-')[0]?ramal.fullname?.split('-')[0]:ramal.fullname,
                        setor:ramal.fullname?.split('-')[1]? ramal.fullname?.split('-')[1] :ramal.fullname
                    }
                });
                ramais = ramais.filter(ramal => {
                    return ramal.fullname?.toLowerCase().includes(e.target.value.toLowerCase()) || ramal.extension?.includes(e.target.value);
                });
                setRamaisOnView(ramais);
            }else setOnView();
        }
    }

    useEffect(() => {
        setOnView();
    }, [props.ucms,props.ucmSelected]);

    return (
        
        <div className='ramais-to-add-main'>
            <div className='ramais-to-add-header'>
                <h2>Adicionar Ramais</h2>
                <select onChange={e => {
                        props.setUcmSelected(e.target.value);
                    }}>
                        <option value={null}>Selecione uma UCM</option>
                        {
                            props.ucms?.map((ucm,index) => {
                                return (
                                    <option key={index} value={ucm._id}>{ucm.cidade} - {ucm.local} 
                                    </option>
                                )
                            })
                    }
                </select>
               <input type='text' placeholder='Pesquisar Ramal' onChange={searchRamais}/>

               {
                props.ucmSelected && props.ucms && ramaisOnView.length > 0 ?
                <div className='div-input-add-all'>
                    <label>
                        Selecionar todos
                    </label>
                    <input type='checkbox' checked={props.selectedToAdd.length === ramaisOnView.length} onChange={e => {
                        if(e.target.checked){
                            props.setSelectedToAdd([...ramaisOnView]);
                        } else {
                            props.setSelectedToAdd([]);
                        }
                    }
                    }/>
                </div>
                :""
            }
            </div>
          
            <li className='ramais-to-add-list'>
                {
                    // props.ucmSelected && props.ucms?.map((ucm,index) => {
                    //     if(ucm._id === props.ucmSelected){
                    //         return (
                    //             ucm?.extensions?
                    
                            ramaisOnView.map((ramal,index) => {
                                    let isOnList = props.list.ramais?.find(r => r.extension === ramal.extension && r.ucm === ramal.ucm);
                                    return (
                                    <div className={`ramais-to-add-list-item ${isOnList?'added-already':''}`} key={index}>
                                            <div className='ramais-to-add-list-item-header'>
                                                <h3>{ramal.extension}</h3>
                                                <h3>{ramal.fullname}</h3>
                                                <input type='checkbox' checked={props.selectedToAdd.find(r => r.extension === ramal.extension && r.ucm === ramal.ucm)} onChange={e => {
                                                    let selecteds =props.selectedToAdd;
                                                    if(e.target.checked){
                                                        props.setSelectedToAdd([...selecteds,ramal]);
                                                    } else {
                                                        let newSelected = [...selecteds];
                                                        newSelected = newSelected.map(r => {
                                                            if(r.extension === ramal.extension && r.ucm === ramal.ucm){
                                                                return null;
                                                            } else return r;
                                                        });
                                                        newSelected = newSelected.filter(r => r !== null);
                                                        props.setSelectedToAdd(newSelected);
                                                    }
                                            }}/>
                                        </div>
                                    </div>
                                    )
                                })
                                
                                // )
                    //     }
                    // })
                }

            </li>
        </div>
    );
};

export default AddRamaisTable;