import React, { useState } from 'react'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'

const Pagination = ({ postsPerPage, totalPosts, currentPage, setCurrentPage }) => {
  const [pageNumberLimit, setPageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)
  const pageNumbers = []

  for(let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i)
  }

  const handlePrev = () => {
    setCurrentPage(currentPage - 1)

    if((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }

  const handleNext = () => {
    setCurrentPage(currentPage + 1)

    if(currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  let pageIncrement = null

  if(pageNumbers.length > maxPageNumberLimit) {
    pageIncrement = <button onClick={handleNext} disabled={currentPage === pageNumbers[pageNumbers.length - 1] ? true : false}>&hellip;</button>
  }

  let pageDecrement = null

  if(pageNumbers.length > maxPageNumberLimit) {
    pageDecrement = <button onClick={handlePrev} disabled={currentPage === pageNumbers[0] ? true : false}>&hellip;</button>
  }

  return (
    <div className='pagination'>
      <button 
        onClick={handlePrev}
        disabled={currentPage === pageNumbers[0] ? true : false}
        className='flex'
      >
        <MdOutlineKeyboardArrowLeft size={20}/>
      </button>
      {pageDecrement}
      {pageNumbers.map((page) => {
        if(page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
          return (
            <button 
              key={page}
              className={page === currentPage ? 'active' : ''}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </button>
          )
        } else {
          return null
        }
      })}
      {pageIncrement}
      <button 
        onClick={handleNext}
        disabled={currentPage === pageNumbers[pageNumbers.length - 1] ? true : false}
        className='flex'
      >
        <MdOutlineKeyboardArrowRight size={20}/>
      </button>
    </div>
  )
  
}

export default Pagination