import React, { useState ,useEffect, useContext} from 'react';
import Options from '../../options';
import agenteDetalhes from '../../../../../components/pdfs/AgenteDetalhes';

import GraficoSimples from '../../../../../components/Graph/ChamadasPie';

import {UserContext} from '../../../../../contexts/UserContext';
import {InfosContext} from '../../../../../contexts/InfosContext';
import {formatDate,secondsToFullTime} from '../../../../../components/utils/index';




function AgentDetails(props) {
    
    const [agent, setAgent] = useState({});
    const [options, setOptions] = useState({
        start: '',
        end: ''
    });

    const {user,pref} = useContext(UserContext);
    const {periodo} = useContext(InfosContext);
    const getTotal = ()=>{
        return agent.details?.length;
     }
     const getAnswered = ()=>{
         let answered = 0;
         agent.details?.forEach(detail => {
             detail.connect=='yes' ? answered+=1 : answered+=0;
         });
         return answered;
     }
     const getAbandonadas = ()=>{
         let abandonadas = 0;
         agent.details?.forEach(detail => {
             detail.connect=='no' ? abandonadas+=1 : abandonadas+=0;
         });
         return abandonadas;
     }
     const getAbandonRate = ()=>{
         let abandonRate = (getAbandonadas()/getTotal())*100;
         abandonRate = isNaN(abandonRate) ? 0 : abandonRate.toFixed(2);
         return abandonRate + '%';
     }
     const getAvgWait = ()=>{
         let avgWait = 0;
         agent.details?.forEach(detail => {
             avgWait += parseInt(detail.wait_time);
         });
         avgWait = avgWait/getTotal();
         avgWait = isNaN(avgWait) ? 0 : avgWait.toFixed(2);
         return avgWait;
     }
     
    const getAvgTalk = ()=>{
        let avgTalk = 0;
        agent.details?.forEach(detail => {
            avgTalk += parseInt(detail.talk_time);
        });
        avgTalk = avgTalk/getTotal();
        avgTalk = isNaN(avgTalk) ? 0 : avgTalk.toFixed(2);
        return avgTalk;
    }

    const formatToHours = (time) => {
        time = time.split(' ')[1].split(':')
        return time[0] + ':' + time[1];
    }
    useEffect(() => {
        setAgent({...props.agent});
    }, [props.agent]);

    const hadleChangeTime = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setOptions({...options, [name]: value});
    }

    const filterBetweenHours = (start, end) => {
        let details = [];
        agent.details.forEach(detail => {
            let time = formatToHours(detail.start_time);
            if(time >= start && time <= end){
                details.push(detail);
            }
        });
        setAgent({...agent, details});
    }
    const clearFilter = () => {
        setAgent({...props.agent});
        setOptions({start: '', end: ''});
    }


    return (
        <div className='agent-details'>
            <div className='agent-details-header'>
                <div className='agent-details-header-title'>
                    <h1>{agent.agent&& (agent.agent.fullname ? agent.agent.fullname:agent.agent.extension)}</h1>
                </div>
                <div className='agent-details-header-options'>
                </div>
            </div>
            <div className='resumo-dos-detalhes'>
                <table className='table table-striped'>
                    <thead className='thead-dark'>
                        <tr>
                            <th>Atendidas</th>
                            <th>Abandonadas</th>
                            <th>Taxa de Abandono</th>
                            <th>Tempo Médio de Espera</th>
                            <th>Tempo Médio de Atendimento</th>
                        </tr>
                    </thead>
                    <tbody className='tbody-light'>
                        <tr>
                            <td>{getAnswered()}</td>
                            <td>{getAbandonadas()}</td>
                            <td>{getAbandonRate()}</td>
                            <td>{secondsToFullTime(getAvgWait())}</td>
                            <td>{secondsToFullTime(getAvgTalk())}</td>
                        </tr>
                    </tbody>
                </table>
            <div className='grafico-simples-detalhes'>
                <GraficoSimples
                    atendidas={getAnswered()}
                    abandonadas={getAbandonadas()}
                    id='grafico-simples-detalhes'
                />
            </div>
            <div>
            <div className='options'>
            <div className='options-title'>
                <h2>Intervalo de Tempo</h2>
            </div>
            <div className='options-inputs'  >
                <div className='row-date'>   
                    <label htmlFor="start" style={{marginRight: '80px'}}>
                     Início:</label>
                    <input type="time" id="start" name="start" value={options.start} onChange={hadleChangeTime} style={{width:"120px    "}}/>
                </div>
                <div className='row-date' >
                    <label htmlFor="end" style={{marginRight: '80px'}}>
                        Fim:</label>
                    <input type="time" id="end" name="end" value={options.end} onChange={hadleChangeTime} style={{width:"120px  "}}/>
                </div>
                <br />
            </div>
            <div className='buttons-options'>
                <button className='btn-opt' onClick={clearFilter}>Limpar</button>
                <button className='btn-opt' onClick={() => filterBetweenHours(options.start, options.end)}>Aplicar</button>
            </div>
            </div>
            </div>
            </div>

                <div className='agent-statistics-details-header'>
                    <h1>Detalhes</h1>
                </div>
                <table className='table table-striped'>
                    <thead className='thead-dark'>
                        <tr>
                            <th>Atendida</th>
                            <th>Número</th>
                            <th>Data</th>
                            <th>Hora</th>
                            <th>Tempo de Espera</th>
                            <th>Tempo de Conversa</th>
                        </tr>
                    </thead>
                    <tbody className='tbody-light'>
                        {agent.details?.map((stat) => {
                            return (
                                <tr key={stat.callId} >
                                    <td className={stat.connect=='yes' ? 'atendida' : 'abandonada'}>{stat.connect=='yes' ? 'Sim' : 'Não'}</td>
                                    <td>{stat.callernum}</td>
                                    <td>{formatDate(stat.start_time.split(" ")[0])}</td>
                                    <td>{formatToHours(stat.start_time)}</td>
                                    <td>{secondsToFullTime(stat.wait_time)}</td>
                                    <td>{secondsToFullTime(stat.talk_time)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div>
                    <button className='download-button' onClick={() => {
                        agenteDetalhes(agent, periodo, pref, user,props.queue,options);
                    }}>Download</button>                
                </div>
                <div >
                    <button  onClick={() => props.changeView('queue')} className='back-button'>Voltar</button>
                </div>
            </div>
    );
}


export default AgentDetails;