
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {formatDate,secondsToFullTime,setPeriodoPdf} from '../utils';
async function agenteResumo(agent, periodo, pref, user) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    console.log('agent', agent);

    let ramal = agent.agent.extension
    let fila = agent.queue
    const imageToDataUrl=(id)=>{
        let image =document.getElementById(id)
        let data = image?.toDataURL();
        return data
    }

    const abandonRate=agent=>{
        let result = (agent.abandonadas/agent.total)*100;
        result = isNaN(result) ? 0 : result.toFixed(2)
        return result + '%';
    }
    const stats = agent.stats;
    const header = [
        {
            text: `Estatísticas do Agente`,
            style: 'header',
            margin: [0, 0, 0, 10],
            alignment: 'center',
        },
        // {
        //     columns: [
        //         {
        //             text: 'Usuário: ',
        //             style: 'subheader',
        //         },
        //         //usuario value same line
        //         {
        //             text: user.nome,
        //             style: 'simple',
        //             margin: [-175, 10, 0, 10],
        //         },
        //     ]
        // },
        {
            columns: [
                {
                    text: 'Cliente: ',
                    style: 'subheader',
                },
                {
                    text: pref.nome,
                    style: 'simple',
                    margin: [-165, 10, 0, 10],
                },
                
            ],
        },
        {
            columns: [
                {
                    text: 'Período Selecionado: ',
                    style: 'subheader',                    
                },
                {
                    text: setPeriodoPdf(periodo),
                    style: 'simple',
                    margin: [-100, 12, 0, 10],
                    
                },
            ],
        },
        {
            columns: [
                {
                    text: "Data de Emissão: ",
                    style: 'subheader',
                },
                {
                    text: formatDate(new Date().toISOString().slice(0, 10)),
                    style: 'simple',
                    alignment: 'justify',
                    margin: [-125, 12, 0, 50]
                },
            ],
        },
        {
            table: {
                widths: ['auto', 150, 'auto', 70],
                body: [
                    [
                        {
                            text: 'Nome: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: agent.agent.fullname.replace(/_/g, ' '),
                            style: 'statsHeader',

                        },
                        {
                            text: 'Ramal: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: agent.agent.extension,
                            style: 'statsHeader',

                        },
                    ],
                    [
                        {
                            text: 'Fila: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: agent.fila +'/'+ fila,
                            style: 'statsHeader',
                        },
                        {
                            text: 'Total de Chamadas: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: agent.total,
                            style: 'statsHeader',
                        },
                      
                    ],
                    [
                        {
                            text: 'Total de Chamadas Atendidas: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: agent.atendidas,
                            style: 'statsHeader',
                        },
                       
                        {
                            text: 'Taxa de Abandono: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: abandonRate(agent),
                            style: 'statsHeader',
                        },
                    ],
                    [
                        
                        {
                            text: 'Tempo Médio de Conversa: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: secondsToFullTime(agent.tempoConversa),
                            style: 'statsHeader',
                        },
                        {
                            text: 'Total de Chamadas Abandonadas: ',
                            style: 'subtitleHeader',
                            alignment: 'left',
                        },
                        {
                            text: agent.abandonadas,
                            style: 'statsHeader',
                        },
                    ]
                ]   
                     
            }
        },
        {
            image: imageToDataUrl('graph-pie-agent-on-view'),
            width: 390,
            margin: [0, 50, 0, 10],
            alignment: 'center',
        },
        
    ]
    const content = [
        {
            pageBreak: 'before',
            text: 'Estatísticas do Agente',
            style: 'header',
            alignment: 'center',
            margin: [0, 0, 0,20]
        },
        {
            table: {
                headerRows: 1,
                widths: ['*', 150, 'auto', 70],
                body: [
                    [
                        {
                            text: 'Data',
                            style: 'tableHeader',
                        },
                        {
                            text: 'Atendidas',
                            style: 'tableHeader',
                        },
                        {
                            text: 'Abandonadas',
                            style: 'tableHeader',
                        },
                        {
                            text: 'Total',
                            style: 'tableHeader',
                        },
                    ],
                    ...stats.map(stat => {
                        return [
                            {
                                text: stat.startTime,
                                style: 'tableBody',
                            },
                            {
                                text: stat.answered_calls,
                                style: 'tableBody',
                            },
                            {
                                text: parseInt(stat.total_calls) - parseInt(stat.answered_calls),
                                style: 'tableBody',
                            },
                            {
                                text: stat.total_calls,
                                style: 'tableBody',
                            },
                        ];
                    }),
                ],

            },
            layout: 'lightHorizontalLines',
        },
        {
            
            pageOrientation: 'landscape',
            pageBreak: 'before',
            
            text: 'Gráficos',
            style: 'header',
            alignment: 'center',
            margin: [0, 0, 0, 20]
        },{
            image: imageToDataUrl('graph-bar-agent-on-view'),
            height: 300 ,
            width: 750,
        }
    ];
    const docDefinition = {
        content: [...header, ...content],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            subtitleHeader: {
                fontSize: 15,
                bold: true,
                margin: [0, 10, 0, 5],
                fillColor: '#343a40',
                color: '#fff'
            },
            statsHeader: {
                fontSize: 14,
                bold: false,
                margin: [0, 10, 0, 5],
                // fillColor: '#343a40',
                fillColor: "#ced7e5",
                color: '#000'
            },
            simple: {
                fontSize: 14,
                margin: [0, 5, 0, 5]
            },
            tableHeader: {
                bold: true,
                fontSize: 15,
                fillColor: '#343a40',
                color: '#fff'
            },
            tableBody: {
                fontSize: 14,
                fillColor: '#ced7e5',
                color: '#000'

            }
        }
    };
    pdfMake.createPdf(docDefinition).open();

}
export default agenteResumo