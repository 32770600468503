import React, { useEffect ,useState, useContext} from 'react';
import GraficoSimples from '../../../../../components/Graph/ChamadasPie';
import GraficoBar from '../../../../../components/Graph/ChamadasBar';
import filaPDF from '../../../../../components/pdfs/FIlaPDF';
import {FcExpand} from 'react-icons/fc';
import {FcCollapse} from 'react-icons/fc';

import {UserContext} from '../../../../../contexts/UserContext';
import {InfosContext} from '../../../../../contexts/InfosContext';
import {formatDate,positive,secondsToFullTime} from '../../../../../components/utils/index';


function QueueDetails(props) {
    const [queueOnView, setQueueOnView] = useState({});
    const [totalDoPeriodo, setTotalDoPeriodo] = useState(0);
    const [answeredTotal, setAnsweredTotal] = useState(0);
    const [abandonedTotal, setAbandonedTotal] = useState(0);
    const [waitingTime, setWaitingTime] = useState(0);
    const [talkTime, setTalkTime] = useState(0);

    const [search, setSearch] = useState('');
    
    const {user,pref} = useContext(UserContext);
    const {periodo} = useContext(InfosContext);

    function calculateAbandonRate(total, notAnswered){
        let result = (notAnswered/total)*100;
        result = isNaN(result) ? 0 : result.toFixed(2)
        return result + '%';
    }
  
    useEffect(() => {
        if(queueOnView.stats){
            let total = 0;
            let answered = 0;
            let abandoned = 0;
            let waiting = 0;
            let talk = 0;
            queueOnView.stats.statistics?.forEach((opt) => {
                total += parseInt(opt.total_calls);
                answered += parseInt(opt.answered_calls);
                abandoned += parseInt(opt.total_calls) - parseInt(opt.answered_calls);
                waiting +=  parseInt(positive(opt.avg_wait));
                talk += parseInt(positive(opt.avg_talk));
            });
            console.log(talk,waiting)
            talk = talk/queueOnView.stats.statistics?.length;
            waiting = waiting/queueOnView.stats.statistics?.length;
            setTotalDoPeriodo(total);
            setAnsweredTotal(answered);
            setAbandonedTotal(abandoned);
            setWaitingTime(waiting);
            setTalkTime(talk);
        }
    }, [queueOnView]);

    useEffect(() => {
        setQueueOnView({...props.queue});
    }, [props.queue]);

    const getDataToView =(statistics)=>{
        let data = [];
        statistics?.forEach((stat) => {
            data.push({
                atendidas: parseInt(stat.answered_calls),
                abandonadas:parseInt(stat.total_calls) - parseInt(stat.answered_calls), 
                total: parseInt(stat.total_calls),
            })
        });
        return data;
    }
    const getLabelsDateToView = (statistics) => {
        let data = [];
        statistics?.forEach((stat) => {
            data.push(stat.startTime);
        });
        return data;
    }
    return (
        <div className='queues-details'>
            <div className='queue-details-header'>
                <h1>Detalhes da Fila</h1>                
                <h2>{queueOnView.extension} - {queueOnView.queue_name?.replace(/_/g, ' ')}</h2>
                 <Members members={queueOnView.members} setMemberOnView={props.setMemberOnView} selectedUcm={props.selectedUcm} />
            </div>
            <div className='queue-summary'>
                <h2>Resumo do Intervalo</h2>
                <div className='queue-summary-table'>
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                            <CabeçalhoTable ths={['Total', 'Atendidas', 'Abandonadas',  'Taxa de Abandono', 'Tempo Médio de Espera','Tempo Médio de Conversa',]} />
                        
                            <tbody className='thead-light'>
                                <tr>
                                    <td>{totalDoPeriodo}</td>
                                    <td>{answeredTotal}</td>
                                    <td>{abandonedTotal}</td>
                                    <td>{calculateAbandonRate(totalDoPeriodo, abandonedTotal)}</td>
                                    <td>{secondsToFullTime(waitingTime)}</td>
                                    <td>{secondsToFullTime(talkTime)}</td>
                                    
                                </tr>
                        </tbody>
                    </table>
                    <div>
                        <GraficoSimples atendidas={answeredTotal} abandonadas={abandonedTotal} id='pie-details-queue' />
                    </div>
                </div>
            </div>
            <div className='queue-details-body'>
                <h2>Detalhes por data do intervalo</h2>
                <div className='queue-data'>
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <CabeçalhoTable ths={
                            [ "Data", "Total", "Atendidas", "Abandonadas", "Taxa de atendidas", "Taxa de Abandono", "Tempo Médio de Espera", "Tempo Médio de Conversa"]
                        } />
                        <tbody className='thead-light'>
                            {
                                queueOnView.stats?.statistics?.map((statistic, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{width:"10%"}}>{formatDate(statistic.startTime)}</td>
                                            <td>{statistic.total_calls}</td>
                                            <td>{statistic.answered_calls}</td>
                                            <td>{
                                                statistic.total_calls - statistic.answered_calls
                                                }</td>
                                            <td>{statistic.answered_rate} %</td>
                                            <td>{
                                                calculateAbandonRate(statistic.total_calls,(statistic.total_calls - statistic.answered_calls))
                                                }</td>
                                            <td>{secondsToFullTime(statistic.avg_wait)}</td>
                                            <td>{secondsToFullTime(statistic.avg_talk)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div>
                        {
                        queueOnView.stats?.statistics &&
                            <GraficoBar stats={getDataToView(queueOnView.stats.statistics)} labels={getLabelsDateToView(queueOnView.stats.statistics)} id='general-queue-graph'/>
                        }
                    </div>
                </div>
            </div>
            <div>
                <button onClick={() => props.changeScreen('list')} className='back-button'>Voltar</button>

            </div>
            <div >
                <button className='download-button' onClick={() =>{
                    filaPDF(queueOnView, totalDoPeriodo, answeredTotal, abandonedTotal, waitingTime, talkTime,periodo,user,pref);
                }}>Download PDF</button>

            </div>
        </div>
    );
}

function CabeçalhoTable(props){
    return (
        <thead className='thead-dark'>      
            <tr>
        {
            props.ths.map((th, index) => {
                return (
                    <th key={index}>{th}</th>
                )
            })
        }
            </tr>
        </thead>
    )
}

function Members(props){
    const [openMembers, setOpenMembers] = useState(false);
    return ( 
        <div className='queue-members'>
            <div>
                <h2 className='queue-members-header-div' onClick={() => setOpenMembers(!openMembers)}>
                <div className='icon-show-members'>
                {
                        openMembers ?
                        <FcCollapse onClick={() => setOpenMembers(!openMembers)} className="arrow-members" />
                        :
                        <FcExpand onClick={() => setOpenMembers(!openMembers)} className="arrow-members" />
                    }
                </div>
                    Agentes : {props.members?.split(',')?.length}
                </h2>
            </div>
            <div className='queue-members-list'>
            {
                openMembers ?
                    props.members?.split(',').map((member, index) => {
                        return(
                            <div key={index} className='queue-member'>
                                <div className='queue-member-title'>
                                    Ramal - {member}                                   
                                </div>
                                <div className='queue-member-title'>
                                {props.selectedUcm?.extensions?.find((m) => m.extension === member).fullname}
                                </div>
                                <div className='members-buttons'>
                                    <button className='agente-button' onClick={() => {props.setMemberOnView(member, 'resume')}}> Resumo </button>
                                    <button className='agente-button' onClick={() => {props.setMemberOnView(member, 'details')}}> Detalhes </button>
                                </div>
                            </div>
                        )
                    })
                :
                ""
            }
            </div>
        </div>
)
}

export default QueueDetails;