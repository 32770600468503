import React, { useEffect } from 'react';
import {useContext,useState} from 'react';
import {UserContext} from '../../../../contexts/UserContext';
import {RiDeleteBinFill} from 'react-icons/ri';
import {CgArrowsExpandUpRight} from 'react-icons/cg';
import api from '../../../../services/api';
function Management(props) {
    const {user,pref} = useContext(UserContext);
    const [linkDeRamais, setLinkDeRamais] = useState(pref?.services.voxcount.linkDeRamais);

    const novaLista = async () => {
        let newLinkDeRamais = [...linkDeRamais];
        let newList ={
            _id:'',
            nome:'Nova Lista',
            ramais:[]
        }
        newLinkDeRamais.push(newList);
        await api.post("/linkDeRamais/novaLista" ,{lista:newList}).catch(err => console.log(err));
    }

    const openListNewTab = (nome) => {
        let org = user.organização;
        let url = `http://tarifador.herokuapp.com/ramais/${org}/${nome}`;
        window
            .open(url, '_blank')
            .focus();
            
    }

    const deleteList = async(index) => {
        let newLinkDeRamais = [...linkDeRamais];
        await api.post("/linkDeRamais/deletarLista",{lista:newLinkDeRamais[index]}).catch(err => console.log(err));
    }

    useEffect(() => {  
        let newLinkDeRamais = [...pref?.services.voxcount.linkDeRamais];
        setLinkDeRamais(newLinkDeRamais);
    }, [pref])
    return (
            <div className='manager-div'>
                <h2>Minhas Listas de Ramais</h2>
                    <div className='manager-tabela-de-ramais'>
                        <table >
                            <thead>
                                <tr>
                                    <th style={{width:"50%"}}>Nome da Lista</th>
                                    <th>Ramais</th>
                                    <th style={{width:"0"}}>Editar</th>
                                    <th style={{width:"10%"}}>Excluir</th>
                                </tr>
                            </thead>
                            <tbody>
                        {
                            linkDeRamais?.map((link,index) => {
                                return (
                                    <tr key={index}>
                                        <td>{link.nome}
                                            <CgArrowsExpandUpRight className='icon-open-link' onClick={e=>openListNewTab(link.nome)}/>
                                        </td>
                                        <td>{link.ramais.length}</td>
                                        <td>
                                            <button className='btn btn-edit' onClick={e => {
                                                e.preventDefault();
                                                props.changeScreen('editRamais',link);
                                            }}>Editar Ramais</button>
                                        </td>
                                        <td>
                                            <RiDeleteBinFill className='btn-exclude' onClick={e => {
                                                e.preventDefault();
                                                deleteList(index);
                                            }}/>
                                        </td>
                                    </tr>
                                )
                            })
                        }   
                            </tbody>
                        </table>

                  <div className="criar-listas">
                            <button className='btn btn-creation' onClick={novaLista}>{
                                linkDeRamais.length === 0 ? 'Criar Primeira Lista' : 'Criar Nova Lista'
                            }</button>
                    </div>
            </div>
            <div>
                <button className='back-button' 
                    onClick={() => props.propsChangesScreen('infos')}>Voltar</button>
            </div>
        </div>
    );
}

export default Management;