
import React from 'react';
import {useContext,useState,useEffect} from 'react';
import {UserContext} from '../../../../contexts/UserContext';
import {UCMSContext} from '../../../../contexts/UCMSContext';
import {MdRemoveCircleOutline} from 'react-icons/md';

import EditTable from './EditTable';
import AddRamaisTable from './AddRamaisTable';
import api from '../../../../services/api';

import './styles.css';
function EditRamais(props) {
    const [list, setList] = useState(props.list);

    const [selectedToAdd, setSelectedToAdd] = useState([]);
    const [selectedToRemove, setSelectedToRemove] = useState([]);
    const [loading, setLoading] = useState(false);
    const {user,pref} = useContext(UserContext);

    const {ucms} = useContext(UCMSContext);

    const [ucmSelected, setUcmSelected] = useState(null);

    const addRamal = (ramal) => {        
        if(!list.ramais.find(r=>r.extension === ramal.extension && r.ucm === ramal.ucm)){
            let nome=ramal.fullname
            let setor =ramal.fullname
            let splitFullName =ramal.fullname?.split('-');
            if(splitFullName.length > 1){
                nome = splitFullName[0];
                setor = splitFullName[1];
            }
            let newRamal = {
                extension:ramal.extension,
                ucm:ramal.ucm,
                fullname:ramal.fullname,
                local:ramal.local,
                name:nome,
                setor:setor,
            }
            let newList = {...list};
            newList.ramais.push(newRamal);
            setList(newList);
        }        
    }
    const addMany = () => {
        for(let ramal of selectedToAdd){
            addRamal(ramal);
        }
        setSelectedToAdd([]);
    }

    const deleteMany = () => {
        let newList = {...list};
        newList.ramais = newList.ramais.filter(ramalAtual => !selectedToRemove.find(ramal => ramalAtual.extension === ramal.extension && ramalAtual.ucm === ramal.ucm));
        setList(newList);
        setSelectedToRemove([]);
    }


    const saveList = async() => {
        setLoading(true);
        await api.post("/linkDeRamais/editarListas" ,{lista:list}).catch(err => console.log(err));
        alert('Lista Salva');
        setLoading(false);
    }

    
    return (
        <div className='edit-ramais-div'>
            <h2>Editar Ramais</h2>          
            <button className='btn btn-primary' onClick={saveList} disabled={loading}>Salvar</button>
            <div className='buttons-header'>
                <button className={`remove-many-button ${selectedToRemove.length === 0 ? 'btn-inactive' : ''}`} onClick={deleteMany} disabled={selectedToRemove.length === 0}>
                        Remover {selectedToRemove.length} ramais
                </button>
                <button className={`add-many-button ${selectedToAdd.length === 0 ? 'btn-inactive' : ''}`} onClick={addMany} disabled={selectedToAdd.length === 0}>
                    Adicionar {selectedToAdd.length} ramais
                </button>
            </div>
            <div className='edit-tela-de-ramais'>
            <AddRamaisTable
                list={list}
                selectedToAdd={selectedToAdd}
                setSelectedToAdd={setSelectedToAdd}
                ucmSelected={ucmSelected}
                setUcmSelected={setUcmSelected}
                ucms={ucms}
            />

            <EditTable
                list={list}
                setList={setList}
                selectedToRemove={selectedToRemove}
                setSelectedToRemove={setSelectedToRemove}
                ucms={ucms}

            />
                
            </div>
            <div>
                <button className='back-button' 
                    onClick={() => props.changeScreen('manager')}>Voltar</button>
            </div>
        </div>
    );
}

export default EditRamais;