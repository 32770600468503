
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDate,secondsToFullTime,setPeriodoPdf } from '../utils/index';

async function agenteDetalhes(agent, periodo, pref, user,queue, options ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;


    const imageToDataUrl=(id)=>{
        let image =document.getElementById(id)
        let data = image?.toDataURL();
        return data
    }
    const formatToHours = (time) => {
        time = time.split(' ')[1].split(':')
        return time[0] + ':' + time[1];
    }
    const details = agent.details; 

    const getTotal = ()=>{
       return details.length;
    }
    const getAnswered = ()=>{
        let answered = 0;
        details.forEach(detail => {
            detail.connect=='yes' ? answered+=1 : answered+=0;
        });
        return answered;
    }
    const getAbandonadas = ()=>{
        let abandonadas = 0;
        details.forEach(detail => {
            detail.connect=='no' ? abandonadas+=1 : abandonadas+=0;
        });
        return abandonadas;
    }
    const getAbandonRate = ()=>{
        let abandonRate = (getAbandonadas()/getTotal())*100;
        abandonRate = isNaN(abandonRate) ? 0 : abandonRate.toFixed(2);
        return abandonRate + '%';
    }
    const getAvgWait = ()=>{
        let avgWait = 0;
        details.forEach(detail => {
            avgWait += parseInt(detail.wait_time);
        });
        avgWait = avgWait/getTotal();
        avgWait = isNaN(avgWait) ? 0 : avgWait.toFixed(2);
        return avgWait;
    }

    const getAvgTalk = ()=>{
        let avgTalk = 0;
        details.forEach(detail => {
            avgTalk += parseInt(detail.talk_time);
        });
        avgTalk = avgTalk/getTotal();
        avgTalk = isNaN(avgTalk) ? 0 : avgTalk.toFixed(2);
        return avgTalk;

    }
    const isOptionsColunm = ()=>{
        return options.start && options.end ? true : false;
    }
    const header = [
        {
            text: 'Detalhes do Agente',
            style: 'header',
            margin: [0, 0, 0, 10],
            alignment: 'center',
        },
        // {
        //     columns: [
        //         {
        //             text: 'Usuário: ',
        //             style: 'subheader',
        //         },
        //         //usuario value same line
        //         {
        //             text: user.nome,
        //             style: 'simple',
        //             margin: [-155, 7, 0, 10],

        //         },
        //     ]
        // },
        {
            columns: [
                {
                    text: 'Cliente: ',
                    style: 'subheader',
                },
                {
                    text: pref.nome,
                    style: 'simple',
                       margin: [-142, 7, 0, 10],

                },

            ],
        },
        {
            columns: [
                {
                    text: 'Período Selecionado: ',
                    style: 'subheader',                    
                },
                {
                    text: setPeriodoPdf(periodo),
                    style: 'simple',
                    margin: [-90, 11, 0, 10],
                    
                },
            ],
        },
        
    ]

    if(isOptionsColunm()){
        header.push(
            {
                columns: [
                    {
                        text: 'Horário Inicial: ',
                        style: 'subheader',
                    },
                    {
                        text: options.start,
                        style: 'simple',
                        margin: [-10, 11, 0, 10],
                    },
                    {
                        text: 'Horário Final: ',
                        style: 'subheader',
                    },
                    {
                        text: options.end,
                        style: 'simple',
                        margin: [-14, 11, 0, 10],
                    },
                ],
            },
        )
    }

    header.push(
        {
            columns: [
                {
                    text: "Data de Emissão: ",
                    style: 'subheader',
                },
                {
                    text: formatDate(new Date().toISOString().split('T')[0]),
                    style: 'simple',
                    alignment: 'justify',
                    margin: [-110, 11, 0, 10]
                },
            ],
        },
        {
            columns: [
                {
                    text: 'Fila: ',
                    style: 'subheader',
                },
                {
                    text: queue.queue_name + ' - ' + queue.extension,
                    style: 'simple',
                    margin: [-205, 12, 0, 0],
                    
                },
            ],
        }
    )

    const body = [
        {
            text: 'Detalhes',
            style: 'header',
            margin: [0, 0, 0, 10],
            alignment: 'center',
        },
        {
            table: {
            widths: ['auto', 150, 'auto', 70],
            body: [
    
                [
                    {
                        text: 'Nome: ',
                        style: 'subtitleHeader',
                    },
                    {
                        text: agent.agent.fullname.replace(/_/g, ' '),
                        style: 'statsHeader',

                    },
                    {
                        text: 'Ramal: ',
                        style: 'subtitleHeader',
                    },
                    {
                        text: agent.agent.extension,
                        style: 'statsHeader',

                    },
                ],
                [
                    {
                        text: 'Total de Chamadas: ',
                        style: 'subtitleHeader',
                    },
                    {
                        text: getTotal(),
                        style: 'statsHeader',
                    },
                    {
                        text: 'Total de Chamadas Atendidas: ',
                        style: 'subtitleHeader',
                    },
                    {
                        text: getAnswered(),
                        style: 'statsHeader',
                    },
                ],
                [
                    {
                        text: 'Total de Chamadas Abandonadas: ',
                        style: 'subtitleHeader',
                        alignment: 'left',
                    },
                    {
                        text: getAbandonadas(),
                        style: 'statsHeader',
                    },
                    {
                        text: 'Taxa de Abandono: ',
                        style: 'subtitleHeader',
                    },
                    {
                        text: getAbandonRate(),
                        style: 'statsHeader',
                    },
                ],
                [
                    {
                        text: 'Tempo Médio de Espera: ',
                        style: 'subtitleHeader',
                    },
                    {
                        text: secondsToFullTime(getAvgWait()),
                        style: 'statsHeader',
                    },
                    {
                        text: 'Tempo Médio de Conversa: ',
                        style: 'subtitleHeader',
                    },
                    {
                        text: secondsToFullTime(getAvgTalk()),
                        style: 'statsHeader',
                    },
                ]
            ]   
                 
        },},
        {
            pageBreak: 'before',
            text: 'Detalhes das Chamadas',
            style: 'header',
            margin: [0, 0, 0, 10],
            alignment: 'center',
            
        },
        {
            
            table: {
                headerRows: 1,
                widths: ['auto', '*', '*', 'auto', 'auto'],
                body: [
                    [
                        {
                            text: 'Data',
                            style: 'tableHeader',
                            alignment: 'center',
                        },
                        {
                            text: 'Horário',
                            style: 'tableHeader',
                            alignment: 'center',
                        },
                     
                    {
                        text: 'Duração',
                        style: 'tableHeader',
                        alignment: 'center',
                    },
                    {
                        text: 'Status',
                        style: 'tableHeader',
                        alignment: 'center',
                    },
                    {
                        text: 'Número',
                        style: 'tableHeader',
                        alignment: 'center',
                    },
                ],
                ...details.map((detail) => {
                        return [
                            {
                                text: formatDate(detail.start_time.split(" ")[0]),
                                style: 'tableBody',
                                alignment: 'center',
                            },
                            {
                                text: formatToHours(detail.start_time),
                                style: 'tableBody',
                                alignment: 'center',
                            },
                            {
                                text: secondsToFullTime(detail.talk_time),
                                style: 'tableBody',
                                alignment: 'center',
                            },
                            {
                                text: detail.connect=='yes' ? 'atendida' : 'abandonada',
                                style: 'tableBody',
                                alignment: 'center',
                            },
                            {
                                text: detail.callernum,
                                style: 'tableBody',
                                alignment: 'center',
                            },
                        ]
                    })
                ]
            },
            layout: 'lightHorizontalLines',
            margin: [0, 10, 0, 10],
        },
    ]
    const docDefinition = {
        content: [
            ...header,
            ...body,
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
            },
            subtitleHeader: {
                fontSize: 15,
                bold: true,
                margin: [0, 10, 0, 5],
                fillColor: '#343a40',
                color: '#fff'
            },
            statsHeader: {
                fontSize: 14,
                bold: false,
                margin: [0, 10, 0, 5],
                // fillColor: '#343a40',
                fillColor: "#ced7e5",
                color: '#000'
            },
            simple: {
                fontSize: 14,
                margin: [0, 5, 0, 5]
            },
            tableHeader: {
                bold: true,
                fontSize: 15,
                fillColor: '#343a40',
                color: '#fff'
            },
            tableBody: {
                fontSize: 14,
                fillColor: '#ced7e5',
                color: '#000'

            }
        }
    };
    pdfMake.createPdf(docDefinition).open();

}
export default agenteDetalhes