
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// import HeaderPdf from './HeaderPdf';
//html2canvas
import html2canvas from 'html2canvas';
import { formatDate,setPeriodoPdf } from '../utils/index';

export default async function resumoDasFilas(arrayqueues,user,prefeitura,periodo) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;


    const imageToDataUrl=(extension)=>{
        let image =document.getElementById(`queue-graph-${extension}`)
        let data = image?.toDataURL();
        return data
    }
    
    function getAbandonadas(stats) {
        let abandonadas = 0;
        if(!stats) return abandonadas;
        stats.statistics?.forEach((stat) => {
            abandonadas += (parseInt(stat.total_calls) - parseInt(stat.answered_calls));
        });
        return abandonadas;    
    }

    function getAtendidas(stats) {
        let atendidas = 0;
        if(!stats) return atendidas;
        stats.statistics?.forEach((stat) => {
            atendidas += parseInt(stat.answered_calls);
        });
        return atendidas;    
    }

    function totalCalls(stats) {
        let total = 0;
        if(!stats) return total;
        stats.statistics?.forEach((stat) => {
            total += parseInt(stat.total_calls);
        });
        return total;    
    }

   
    const header =[
        {
            text: 'Resumo das Filas',
            style: 'header',
            alignment: 'center',
            margin: [0, 0, 0, 20]
            
        },

        // {
        //     columns: [
        //         {
        //             text: 'Usuário: ',
        //             style: 'subheader',
        //         },
        //         //usuario value same line
        //         {
        //             text: user.nome,
        //             style: 'simple',
        //         },
        //     ]

        // },
        {
            columns: [
                {
                    text: 'Cliente: ',
                    style: 'subheader',
                },
                //prefeitura value same line
                {
                    text: prefeitura.nome,
                    style: 'simple',
                },
                
            ],
        },
            
        {
            columns: [
                {
                    text: 'Período Selecionado: ',
                    style: 'subheader',
                },
                {
                    text: setPeriodoPdf(periodo),
                    style: 'simple',
                    margin: [-115, 0, 0, 10]
                },
            ],
        },
        {
            columns: [
                {
                    text: "Data de Emissão: ",
                    style: 'subheader',
                },
                {
                    text: formatDate(new Date().toISOString().slice(0, 10)),
                    style: 'simple',
                    alignment: 'justify',
                    margin: [-140, 0, 0, 0]
                },
            ],
        },
    ]
    let content = [...header];


    
    const table = {
        table: {
            headerRows: 1,
            widths: [100,'auto','auto', '*','auto'],
            body: [
                // ['Nome', 'Atendidas' ,' Abandonadas', 'Gráfico','Total'],
               [ {
                    text: 'Nome',
                    style: 'tableHeader',
                },
                {
                    text: 'Atendidas',
                    style: 'tableHeader',
                },
                {
                    text: 'Abandonadas',
                    style: 'tableHeader',
                },
                {
                    text: 'Gráfico',
                    style: 'tableHeader',
                },
                {
                    text: 'Total',
                    style: 'tableHeader',
                },]
            ],
        },
    }

    arrayqueues.forEach((queue,i) => {
          // eight rows per page start with 4
        let linesperpage = 6;
        let firstBreak = 4;
        let insertBreak = false;

        
        // let viewSize= window.innerWidth;
        // if(viewSize <1400){
        //     linesperpage = 5;
        //     firstBreak = 4;
        // }
        // if(viewSize < 1200){
        //     linesperpage = 4;
        //     firstBreak = 2;
        // }
        if ((i-firstBreak) % linesperpage === 0 && i !== 0) {
            insertBreak = true;
        }
        if(i < firstBreak) insertBreak = false;
  
        let arrayObjts = [];
        if(!insertBreak){
            arrayObjts = [  {

                text: queue.queue_name.replace(/_/g, ' '),
                style: 'tableBody',
            },
            {
                text: getAtendidas(queue.stats),
                style: 'tableBody',
            },
            {
                text: getAbandonadas(queue.stats),
                style: 'tableBody',
            },
            {
                image: imageToDataUrl(queue.extension),
                width: 200,
            },
            {
                text: totalCalls(queue.stats),
                style: 'tableBody',
            },
        ]
        }else{
            arrayObjts = [  {
                pageBreak: 'before',
                text: queue.queue_name.replace(/_/g, ' '),
                style: 'tableBody',
            },
            {
                pageBreak: 'before',

                text: getAtendidas(queue.stats),
                style: 'tableBody',
            },
            {
                pageBreak: 'before',

                text: getAbandonadas(queue.stats),
                style: 'tableBody',
            },
            {
                pageBreak: 'before',

                image: imageToDataUrl(queue.extension),
                width: 200,
                
            },
            {
                pageBreak: 'before',

                text: totalCalls(queue.stats),
                style: 'tableBody',
            },]
        }
        table.table.body.push(arrayObjts);
    
    })

    content.push(table);
    


    const styles = {
        header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
        },
        subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 10],
            alignment: 'justify',
        },
        simple: {
            fontSize: 14,
            margin: [-180, 0, 0, 10],
            alignment: 'justify',
        },
        tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black',
            fillColor: "#ced7e5",
        },
        tableBody: {
            fontSize: 12,
            color: 'black',
            bold:2,
            margin: [0, 10, 0, 0],
        },
    };
  
    let docDefinition = {
        content,
        styles,
        pageMargins: [40, 60, 40, 0],
    };
    pdfMake.createPdf(docDefinition).open();
    //download
    // pdfMake.createPdf(docDefinition).download('resumo-das-filas.pdf');
}

async function imgToBase64(img) {
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        console.log(img);
        console.log(reader);
        reader.onload = () => {

            resolve(reader.result);
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(img);
    })
}

async function readFileImg(url) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(url);
    })
}

async function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
        var img = new Image();
        // img.setAttribute("crossOrigin", "anonymous");
        let data = '';
        img.onload = function () {
            var canvas = document.createElement("canvas");
            canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
            canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

            canvas.getContext("2d").drawImage(this, 0, 0);

            // Get raw image data
            // data = canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, "");

            // ... or get as Data URI
            data = canvas.toDataURL();
            resolve(data);
        }
        img.src = url;

    })

}

 function transformHtmlImgToCanvas(){
    return new Promise(async(resolve, reject) => {
        let canvas = await html2canvas(document.getElementById('logo-prefeitura'),{ allowTaint: true, useCORS: true });
        console.log(canvas);
        let data = canvas.toDataURL('image/png');
        resolve(data);
    })	
}



async function getPrefPic(url) {
    let data = await transformHtmlImgToCanvas(url);
    console.log(data);
    return data;
}

  function espera(s){
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, s * 1000);
    })
  }