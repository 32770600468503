import React, { useState ,useEffect, useContext} from 'react';
import GraficoSimples from '../../../../../components/Graph/ChamadasPie';
import GraficoBar from '../../../../../components/Graph/ChamadasBar';
import Options from '../../options';

import angeteResumo from '../../../../../components/pdfs/AgenteResumo';

import {UserContext} from '../../../../../contexts/UserContext';
import {InfosContext} from '../../../../../contexts/InfosContext';
import {formatDate,secondsToFullTime} from '../../../../../components/utils/index';




function AgentResume(props) {
    const [agent, setAgent] = useState({});
    const [atendidas, setAtendidas] = useState(0);
    const [abandonadas, setAbandonadas] = useState(0);
    const [tempoConversa, setTempoConversa] = useState(0);
    const [tempoEspera, setTempoEspera] = useState(0);
    const [total, setTotal] = useState(0);

    const {user,pref} = useContext(UserContext);
    const {periodo} = useContext(InfosContext);


    useEffect(() => { 
        let answered = 0;
        let abandoned = 0;
        let waiting = 0;
        let talk = 0;
        let total = 0;
        props.agent.stats?.forEach((stat) => {
            answered += parseInt(stat.answered_calls);
            abandoned += parseInt(stat.total_calls) - parseInt(stat.answered_calls);
            waiting += parseInt(stat.avg_wait);
            talk += parseInt(stat.avg_talk);
            total += parseInt(stat.total_calls);
        });
        setAtendidas(answered);
        setAbandonadas(abandoned);
        setTempoConversa(talk);
        setTempoEspera(waiting);
        setTotal(total);

        if(waiting>0){
            console.log('first waiting time',waiting);
        }
    }, [props.agent]);

    useEffect(() => {
        setAgent(props.agent);
    }, [props.agent]);
    
    const getLabelsDateToView = (statistics) => {
        let labels = [];
        statistics?.forEach((stat) => {
            labels.push(stat.startTime);
        });
        return labels;
    }
   
    const getDataToView =(statistics)=>{
        let data = [];
        statistics?.forEach((stat) => {
            data.push({
                atendidas: parseInt(stat.answered_calls),
                abandonadas:parseInt(stat.total_calls) - parseInt(stat.answered_calls), 
                total: parseInt(stat.total_calls),
            });
        });
        return data;
    }
    return (
        <div className='agent-resume'>
            <div className='agent-details-header'>
                <div className='agent-details-header-title'>
                    <h1>{agent.agent&& (agent.agent.fullname ? agent.agent.fullname:agent.agent.extension)}</h1>
                </div>
                <div className='agent-details-header-options'>
                </div>
            </div>
         
            <div className='agent-resume-body'>
                <h2>Resumo</h2>
                <table className='table table-striped'>
                    <thead className='thead-dark'>
                        <tr>
                            <th>Total</th>
                            <th>Atendidas</th>
                            <th>Abandonadas</th>
                            <th>Tempo de Conversa</th>
                        </tr>
                    </thead>
                    <tbody className='thead-light'>
                        <tr>
                            <td>{total}</td>
                            <td>{atendidas}</td>
                            <td>{abandonadas}</td>
                            <td>{secondsToFullTime(tempoConversa)}</td>
                        </tr>
                    </tbody>
                </table>
                <br />  
                <div>
                    <GraficoSimples 
                        atendidas={atendidas} 
                        abandonadas={abandonadas}
                        id='graph-pie-agent-on-view'
                    />
                </div>
                <br />
                <table className='table table-striped'>
                    <thead className='thead-dark'>
                        <tr>
                            <th style={{width:"24%"}}>Data</th>
                            <th>Atendidas</th>
                            <th>Abandonadas</th>
                            <th>Tempo de Médio de Conversa</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody className='thead-light'>
                        {props.agent.stats?.map((stat) => {
                            return (
                                <tr>
                                    <td>{formatDate(stat.startTime)}</td>
                                    <td>{parseInt(stat.answered_calls)}</td>
                                    <td>{parseInt(stat.total_calls) - parseInt(stat.answered_calls)}</td>
                                    <td>{secondsToFullTime(parseInt(stat.avg_talk))}</td>
                                    <td>{parseInt(stat.total_calls)}</td>
                                </tr>
                            );
                        }
                        )}
                    </tbody>
                </table>
            </div>
            <br />            
            <div style={{width:"100%"}}>
                <GraficoBar 
                    stats={getDataToView(props.agent.stats)}
                    labels={getLabelsDateToView(props.agent.stats)}
                    id='graph-bar-agent-on-view'
                    />
            </div>
            <div>
                <button className='download-button' onClick={() => {
                  let agentToPrint ={
                        ...agent,
                        atendidas:atendidas,
                        abandonadas:abandonadas,
                        tempoConversa:tempoConversa,
                        fila : props.queue.queue_name,
                        total:total,
                    }
                    angeteResumo(agentToPrint, periodo,pref,user)
                }}>Download</button>                
            </div>
            <div>
                <button  onClick={() => props.changeView('queue')} className='back-button'>Voltar</button>
            </div>
        </div>
    );
}

export default AgentResume;