import React , {useState}from 'react';
import Queues from './quick/index';
import DetailsView from './details/index'
import './styles.css'
function QueuesView(props) {
    const [screenSelected, setScreenSelected] = useState('list');
    const [selectedQueue, setSelectedQueue] = useState({});
    const changeScreen = (screen) => setScreenSelected(screen);

    const screens = {
        list: <Queues changeScreen={changeScreen} setSelectedQueue={setSelectedQueue} selectedUcm={props.selectedUcm} propsScreen={props.changeScreen} />,
        details: <DetailsView changeScreen={changeScreen} queue={selectedQueue}  selectedUcm={props.selectedUcm}/>,
    }
    return (
        <div id='queues-on-view'>
            {screens[screenSelected]}
        </div>
    );
}

export default QueuesView;