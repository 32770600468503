import React  from 'react'
import { useState, useContext, useEffect } from 'react';
import CallGraph from '../../../../components/Graph/ChamadasPie';
import Options from '../options';
import { InfosContext } from '../../../../contexts/InfosContext';
import { UserContext } from '../../../../contexts/UserContext';
import resumoDasFilas from '../../../../components/pdfs/ResumoRapidoDasFilas';
import {AiOutlineUnorderedList} from 'react-icons/ai';
import {MdSpaceDashboard} from 'react-icons/md';
import { secondsToFullTime } from '../../../../components/utils';
import {formatedDate} from '../../../../components/utils';
import './styles.css'

function Index(props) {
    const [queues, setQueues] = useState([]);
    const [queuesOnView, setQueuesOnView] = useState([]);
    const infoContex = useContext(InfosContext);
    const context = useContext(UserContext);
    const [quick, setQuick] = useState([]);
    const [visualization, setVisualization] = useState('dashboard');

    const {periodo, setPeriodo, loadAgentsStats, loadAgentsDetails,search,setSearch } = infoContex; 

    useEffect(() => {
        setQueues([...props.selectedUcm?.queues])
        setQueuesOnView([...props.selectedUcm?.queues])
    }, [props.selectedUcm])

    const searching =(value) => {
        setSearch(value)
        let filteredQueues = queues.filter((queue) => {
            return queue.queue_name.toLowerCase().includes(value.toLowerCase()) || queue.extension.toLowerCase().includes(value.toLowerCase());
        });
        setQueuesOnView(filteredQueues);
    }
    function sortStatsFromQueueByDate(queue) {
        if(queue.stats && queue.stats.statistics){
            queue.stats.statistics.sort((a, b) => {
                return new Date(a.startTime) - new Date(b.startTime);
            });
        }
        if(queue.agentsDetails && queue.agentsDetails.details){
            queue.agentsDetails.details.sort((a, b) => {
                let startTimeA = a.start_time.split(' ')[0];
                let startTimeB = b.start_time.split(' ')[0];
                return new Date(startTimeA) - new Date(startTimeB);
            });
        }
        if(queue.agentsStats && queue.agentsStats.agent_statistics){
            queue.agentsStats.agent_statistics.sort((a, b) => {
                return new Date(a.startTime) - new Date(b.startTime);
            });
        }
        return queue;
    }

    function findByOptions ({start,end},alreadySet=true) {
        
        concatQueusStats()
        if(start && end){
            let newQueusOnView =queues.map((queue) => {
                if(queue.stats && queue.stats.statistics){
                    queue.stats.statistics = queue.stats.statistics.filter((stat) => {
                        return stat.startTime >= start && stat.startTime <= end;
                    });
                }
                if(queue.agentsDetails && queue.agentsDetails.details){
                    queue.agentsDetails.details = queue.agentsDetails.details.filter((agentDetail) => {
                        let startTime = agentDetail.start_time.split(' ')[0];
                        return startTime >= start && startTime <= end;
                    });
                } 
               if(queue.agentsStats && queue.agentsStats.agent_statistics){
                    queue.agentsStats.agent_statistics = queue.agentsStats.agent_statistics.filter((agentStat) => {
                        return agentStat.startTime >= start && agentStat.startTime <= end;
                    });
               }
                return queue;
            });
           
            setQueuesOnView(newQueusOnView);
            if(alreadySet){
                setPeriodo({start,end});
            }
         
        } 
        
    }
    
    const concatQueusStats = ()=>{
        let queuesStats = infoContex.queueStats;
        let agentsDetails = infoContex.agentsDetails;
        let agentsStats = infoContex.agentsStats;
        let newQueues = queues.map((queue) => {
            let newQueueStats = queuesStats.find((queueStats) => queueStats.numero == queue.extension);
            if(newQueueStats){
                queue.stats ={...newQueueStats};
            }
            return queue;
        });
        newQueues = newQueues.map((queue) => {
            let newAgentsDetails = agentsDetails.find((agentsDetails) => agentsDetails.numero == queue.extension);
            if(newAgentsDetails){
                queue.agentsDetails ={...newAgentsDetails};
            }
            return queue;
        });
        newQueues = newQueues.map((queue) => {
            let newAgentsStats = agentsStats.find((agentsStats) => agentsStats.numero == queue.extension);
            if(newAgentsStats){
                queue.agentsStats ={...newAgentsStats};
            }
            return queue;
        });
        newQueues.forEach((queue) => {
            sortStatsFromQueueByDate(queue);
        });
        setQueuesOnView(newQueues);
        
    }
    const generateQuickDownload = (quick) => {
        let queuesToDownload = queuesOnView.filter((queue) => {
            return quick.includes(queue.extension);
        });
        let user = context.user
        let pref = context.pref
        resumoDasFilas(queuesToDownload, user, pref ,infoContex.periodo);
    }
    
    useEffect(() => {
        if(infoContex && infoContex.queueStats.length > 0){
            if(periodo.start && periodo.end){
                findByOptions(periodo,false)
            }else{
                concatQueusStats()
            }

            if(search){
                searching(search)
            }
        }
    }, [infoContex, queues])  

  

    return (
        < div>
            <h1>Filas da {props.selectedUcm && props.selectedUcm.cidade + (props.selectedUcm.local?`( ${props.selectedUcm.local} )`:"")}</h1>
            <br />
            <div className='search-and-set'>

                <input id="#search-queues" type="text" placeholder="Pesquisar" onChange={e=>searching(e.target.value)} className="search" value={search}/>
                <div className="view-set">
                    <AiOutlineUnorderedList className="icon" onClick={() => setVisualization('list')} size={50} />
                    <MdSpaceDashboard className="icon" onClick={() => setVisualization('dashboard')} size={50} />
                </div>      
            </div>
            <br />
            {
                <Options find={findByOptions} clear ={()=>{
                    setPeriodo({start:'', end:''})
                    concatQueusStats()
                }}/>
            }
            <div className="quick-input-div">
                <label>Selecione as filas para gerar o resumo rápido:</label>
                <input type="checkbox" id="all" name="all" value="all" onChange={(e) => {
                    if(e.target.checked){
                        setQuick(queuesOnView.map((queue) => queue.extension));
                    }else{
                        setQuick([]);
                    }
                }}/>
            </div>
            <div >
                {
                    visualization === 'dashboard' && <DashBoard
                            queuesOnView={queuesOnView} 
                            quick={quick} 
                            setQuick={setQuick}
                            setSelectedQueue={props.setSelectedQueue}
                            changeScreen={props.changeScreen}
                        />
                }
                {
                    visualization === 'list' &&  <table className='table table-striped' aria-labelledby="tabelLabel">
                    <thead className='thead-dark'>
                        <tr>
                            <th>
                                <input type="checkbox"
                                className="form-check-input"
                                onClick={(e) => {
                                    if(e.target.checked){
                                        
                                        let newQuicks= queuesOnView.map((queue) => {
                                            return queue.extension;
                                        });
                                        setQuick(newQuicks);
                                    }else{
                                        setQuick([]);
                                    }
                                }} />
                            </th>
                            <th>Nome</th>
                            <th>Número</th>
                            <th>Gráfico</th>
                            <th>Atendidas</th>
                            <th>Abandonadas</th>
                            <th>Detalhes</th>

                        </tr>
                    </thead>
                    <tbody className='thead-light'>             
                    
                        <List 
                                queuesOnView={queuesOnView} 
                                quick={quick} 
                                setQuick={setQuick}
                                setSelectedQueue={props.setSelectedQueue}
                                changeScreen={props.changeScreen}
                            />
                    
                    </tbody>
                </table>
                }
            </div>
            {
                infoContex.loading > 0 && infoContex.loading < 100 &&
                <div className="loading-filter">
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={infoContex.loading} aria-valuemin="0" aria-valuemax="100" style={{width: infoContex.loading + '%'}}>{infoContex.loading}%</div>
                    </div>
                </div>
            }
            {
                loadAgentsStats  && loadAgentsStats<100? <div className="load-div">
                    <div className="progress">
                        <div className="progress-bar-agents progress-bar-agents-stats progress-bar-striped-stats progress-bar-alert-agents-stats" role="progressbar" aria-valuenow={loadAgentsStats} aria-valuemin="0" aria-valuemax="100" style={{width:  '100%'}}>Carregando Estatistícas do Agente {loadAgentsStats}%</div>
                    </div>
                </div>
                : 
                loadAgentsDetails && loadAgentsDetails<100 &&   <div className="load-div">
                    <div className="progress">
                        <div className="progress-bar-agents progress-bar-agents-details progress-bar-striped-details progress-bar-alert-agents-details" role="progressbar" aria-valuenow={loadAgentsDetails} aria-valuemin="0" aria-valuemax="100" style={{width: '100%'}}> Carregando Detalhes do Agente {loadAgentsDetails}%</div>
                    </div>
                </div>
            
            }
           

            {   
            quick.length > 0 && 
                <div className='quick-download'><button onClick={e=>generateQuickDownload(quick)} >
                        Download Rápido
                    </button>
                </div>
            }
            <div>
                <button className='back-button' 
                    onClick={() => props.propsScreen('infos')}>Voltar</button>
            </div>
        </div>
    );
    
}

function List (props){
    return ( 
        <>
        {
            props.queuesOnView.map((queue, index) => {
            return (        
                <tr key={index} className={ evenOdd(index) }>
                    <td>
                        <input type="checkbox" name="queue" value={queue.extension} className="form-check-input" onChange={(e) => {
                            if (e.target.checked) {
                                props.setQuick([...props.quick, queue.extension])
                            } else {
                                props.setQuick(props.quick.filter((item) => item !== queue.extension))
                            }
                        }} 
                        checked={props.quick.includes(queue.extension)}/>
                    </td>            
                    <td>{queue.queue_name}</td>
                    <td>{queue.extension}</td>
                    <td>
                        <CallGraph 
                        size="small"
                        id ={'queue-graph-'+queue.extension}
                        atendidas={queue.stats?getAtendidas(queue.stats):0}
                        abandonadas={queue.stats?getAbandonadas(queue.stats):0}
                    />                                    
                    </td>
                    <td>{queue.stats?getAtendidas(queue.stats):0}</td>
                    <td>{queue.stats?getAbandonadas(queue.stats):0}</td>
                    <td>
                        <button onClick={e=>{
                            props.setSelectedQueue(queue)
                            props.changeScreen('details')
                        }}>Ver detalhes</button>
                    </td>
                </tr>
            )}
        )}
        </>
    )
}

function DashBoard(props){
    return (
        <div className="dashboard">
            {
                props.queuesOnView.map((queue, index) => {
                    return (
                        <div key={index} className="queue-card">
                            <div className="queue-card-header">
                                <div className="queue-card-name">
                                    {queue.queue_name}
                                </div>
                                <div className="queue-card-number">
                                    {queue.extension}
                                </div>
                            </div>
                            <div className="queue-card-body">
                            
                                    {
                                        queue.stats && <div className="queue-card-data">
                                            Total de Chamadas: {getTotalCalls(queue.stats)}
                                        </div>
                                    }
                                    {
                                        queue.stats && <div className="queue-card-data">
                                            Tempo Médio: {secondsToFullTime(getTempoMedio(queue.stats))}
                                        </div>
                                    }
                                     <div className="queue-card-graph">
                                        <CallGraph 
                                            size="small"
                                            id ={'queue-graph-'+queue.extension}
                                            atendidas={queue.stats?getAtendidas(queue.stats):0}
                                            abandonadas={queue.stats?getAbandonadas(queue.stats):0}
                                        />
                                    </div>         
                                    {
                                        queue.stats && <div className="queue-card-data">
                                            Não Atendidas: {getAbandonadas(queue.stats)}
                                        </div>
                                    }
                                    {
                                        queue.stats && <div className="queue-card-data">
                                            Atendidas: {getAtendidas(queue.stats)}
                                        </div>
                                    }
                                   
                            </div>
                            <div className="queue-card-buttons">
                                    <button className='queue-main-details-button' 
                                        onClick={e=>{
                                            props.setSelectedQueue(queue)
                                            props.changeScreen('details')
                                    }}>Ver detalhes</button>
                                    <input type="checkbox" name="queue" value={queue.extension} className="queue-checkbox" onChange={(e) => {
                                        if (e.target.checked) {
                                            props.setQuick([...props.quick, queue.extension])
                                        } else {
                                            props.setQuick(props.quick.filter((item) => item !== queue.extension))
                                        }
                                    }} 
                                    checked={props.quick.includes(queue.extension)}/>
                                </div>
                        </div>
                    )
                })
            }
        </div>
    )
}


function getAbandonadas(stats) {
    let abandonadas = 0;
    stats.statistics?.forEach((stat) => {
        abandonadas += (parseInt(stat.total_calls) - parseInt(stat.answered_calls));
    });
    return abandonadas;    
}

function getAtendidas(stats) {
    let atendidas = 0;
    stats.statistics?.forEach((stat) => {
        atendidas += parseInt(stat.answered_calls);
    });
    return atendidas;    
}
function getTotalCalls(stats) {
    let total = 0;
    stats.statistics?.forEach((stat) => {
        total += parseInt(stat.total_calls);
    });
    return total;    
}
function getTempoMedio(stats) {
    let tempo = 0;
    stats.statistics?.forEach((stat) => {
        tempo += parseInt(stat.avg_wait);
    });
    return tempo;    
}
function evenOdd (index) {
    if (index % 2 === 0) {
        return 'even'
    } else {
        return 'odd'
    }
}

export default Index;