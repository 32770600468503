import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from '../pages/Login'
import Ramais from '../pages/Ramais'
import Dashboard from '../pages/Dashboard'
import Recover from '../pages/Login/password/Recover'
import Change from '../pages/Login/password/Change'

import Authenticator from './Authenticator'


function AppRoutes() {
  return (
        <Routes>
            <Route path='/login' element={<Login />}/>
            <Route path='/dashboard' element={<Authenticator><Dashboard /></Authenticator>}/>
            <Route path="/" element={<Authenticator/>} />
            <Route path='*' element={<h1>404</h1>}/>
            <Route path="/ramais/:nomePref/:nomelista" element={<Ramais />}/>
            <Route path="/recover" element={<Recover />}/>
            <Route path="/changeforgot/:token" element={<Change />}/>
        </Routes>
  )
}

export default AppRoutes