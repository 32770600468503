
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDate,secondsToFullTime ,setPeriodoPdf} from '../utils/index';

async function filaPDF (queueOnView, totalDoPeriodo, answeredTotal, abandonedTotal, waitingTime, talkTime,periodo,user,pref) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    

    let stats =queueOnView?.stats?.statistics?.map((stat) => {
        return stat
    })
    const imageToDataUrl=(id)=>{
        let image =document.getElementById(id)
        let data = image?.toDataURL();
        return data
    }
    function calculateAbandonRate(total, notAnswered){
        let result = (notAnswered/total)*100;
        result = isNaN(result) ? 0 : result.toFixed(2)
        return result + '%';
    }
    const abandonRate = calculateAbandonRate(totalDoPeriodo, abandonedTotal);

    const header = [
        {
            text: 'Estátisticas da Fila',
            style: 'header',
            margin: [0, 0, 0, 10],
            alignment: 'center',
        },
        // {
        //     columns: [
        //         {
        //             text: 'Usuário: ',
        //             style: 'subheader',
        //         },
        //         //usuario value same line
        //         {
        //             text: user.nome,
        //             style: 'simple',
        //         },
        //     ]
        // },
        {
            columns: [
                {
                    text: 'Cliente: ',
                    style: 'subheader',
                },
                {
                    text: pref.nome,
                    style: 'simple',
                },
                
            ],
        },
        {
            columns: [
                {
                    text: 'Período Selecionado: ',
                    style: 'subheader',                    
                },
                {
                    text: setPeriodoPdf(periodo),
                    style: 'simple',
                    margin: [-120, 0, 0, 10],
                    
                },
            ],
        },
        {
            columns: [
                {
                    text: "Data de Emissão: ",
                    style: 'subheader',
                },
                {
                    text: formatDate(new Date().toISOString().split('T')[0]),
                    style: 'simple',
                    alignment: 'justify',
                    margin: [-140, 0, 0, 10]
                },
            ],
        },
        {
            table: {
                widths: ['auto', 150, 'auto', 70],
                fillColor: '#343a40',
                body: [
                    [
                        {
                            text: 'Nome: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: queueOnView.queue_name.replace(/_/g, ' '),
                            style: 'statsHeader',

                        },
                        {
                            text: 'Ramal: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: queueOnView.extension,
                            style: 'statsHeader',

                        },
                    ],
                    [
                        {
                            text: 'Total de Chamadas: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: totalDoPeriodo,
                            style: 'statsHeader',
                        },
                        {
                            text: 'Total de Chamadas Atendidas: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: answeredTotal,
                            style: 'statsHeader',
                        },
                    ],
                    [
                        {
                            text: 'Total de Chamadas Abandonadas: ',
                            style: 'subtitleHeader',
                            alignment: 'left',
                        },
                        {
                            text: abandonedTotal,
                            style: 'statsHeader',
                        },
                        {
                            text: 'Taxa de Abandono: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: abandonRate,
                            style: 'statsHeader',
                        },
                    ],
                    [
                        {
                            text: 'Tempo Médio de Espera: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text: secondsToFullTime(waitingTime),
                            style: 'statsHeader',
                        },
                        {
                            text: 'Tempo Médio de Atendimento: ',
                            style: 'subtitleHeader',
                        },
                        {
                            text:secondsToFullTime(talkTime),
                            style: 'statsHeader',
                        },
                    ]
                ]   
                     
            }
        }
    ]
    const body = [
            {
                image: imageToDataUrl('pie-details-queue'),
                width: 800,
                margin: [-155,120, 0, 20]
            },
        
        {
            pageBreak: 'before',
            table: {

                headerRows: 1,

                body: [
                    [
                        {
                            text: 'Data',
                            style: 'tableHeader',
                            alignment: 'center'
                        },
                        {
                            text: 'Chamadas Atendidas',
                            style: 'tableHeader',
                            alignment: 'center'
                        },
                        {
                            text: 'Chamadas Abandonadas',
                            style: 'tableHeader',
                            alignment: 'center'
                        },
                        {
                            text: 'Taxa de Abandono',
                            style: 'tableHeader',
                            alignment: 'center'
                        },
                        {
                            text: 'Tempo Médio de Espera',
                            style: 'tableHeader',
                            alignment: 'center'
                        },
                        {
                            text: 'Tempo Médio de Atendimento',
                            style: 'tableHeader',
                            alignment: 'center'
                        },
                    ],
                    ...stats.map((data) => {
                        return [
                            {
                                text: data.startTime,
                                alignment: 'center',
                                style: "tableBody"
                            },
                            {
                                text: data.answered_calls,
                                alignment: 'center',
                                style: "tableBody"
                            },
                            {
                                text: (data.total_calls - data.answered_calls),
                                alignment: 'center',
                                style: "tableBody"
                            },
                            {
                                text: calculateAbandonRate(data.total_calls, (data.total_calls - data.answered_calls)),
                                alignment: 'center',
                                style: "tableBody"
                            },
                            {
                                text: secondsToFullTime(data.avg_wait),
                                alignment: 'center',
                                style: "tableBody"
                            },
                            {
                                text: secondsToFullTime(data.avg_talk),
                                alignment: 'center',
                                style: "tableBody"
                            },
                        ]
                    })
                ],
                widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto']

            }
        },
        //got next pdf page in horziontal
        {
            pageOrientation: 'landscape',
            pageBreak: 'before',
            
            text: 'Gráfico-Geral',
            style: 'subheader',
            alignment: 'center',
            margin: [0, 20, 0, 20],
        },
            {
                image: imageToDataUrl('general-queue-graph'),
                height: 300 ,
                width: 750,
            },
        
    ]
    const docDefinition = {
       
        content: [
            ...header,
            ...body
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            subheader: {
                fontSize: 14,
                bold: true,
                margin: [0, 0, 0, 10],
                alignment: 'justify',
            },
            simple: {
                fontSize: 14,
                margin: [-180, 0, 0, 10],
                alignment: 'justify',
            },       


            subtitleHeader: {
                fontSize: 15,
                bold: true,
                margin: [0, 10, 0, 5],
                fillColor: '#343a40',
                color: '#fff'
            },
            statsHeader: {
                fontSize: 14,
                bold: false,
                margin: [0, 10, 0, 5],
                // fillColor: '#343a40',
                fillColor: "#ced7e5",
                color: '#000'
            },


            tableExample: {
                margin: [0, 5, 0, 15]
            },
            tableHeader: {
                bold: true,
                fontSize: 13,
                fillColor: '#343a40',
                color: '#fff'
            },
            tableBody: {
                fontSize: 12,
                fillColor: '#ced7e5',
                color: '#000'

            }
        }
    };
    pdfMake.createPdf(docDefinition).open()//download(`fila-${queueOnView.name}.pdf`);

}



export default filaPDF;