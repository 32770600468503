import React, { useMemo, useState } from 'react'
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import Pagination from '../../../components/Pagination/index'
import '../queues/styles.css'
import '../../../components/Pagination/styles.css'
import ButtonsFile from '../../../components/ButtonsFile/ButtonsFile'
const Extensions = (props) => {

  //console.log(props.selectedUcm.extensions.length -1 )
  const evenOdd = (index) => {
    if (index % 2 === 0) {
        return 'even'
    } else {
        return 'odd'
    }
  }

  const [order, setOrder] = useState(1)
  const [columnOrder, setColumnOrder] = useState('fullname')
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(10) //setting number of posts per page

  let filteredExtensions= useMemo(() => {
    const lowerSearch = search.toLowerCase().trim()
    return props.selectedUcm.extensions?.filter((extension) => lowerSearch === '' ? extension :         extension.extension?.toLowerCase().includes(lowerSearch) || extension.fullname?.toLowerCase().includes(lowerSearch) )
  }, [search, props.selectedUcm.extensions])
  
  const handleOrder = (fieldName) => {
    setOrder(-order)
    setColumnOrder(fieldName)
  }

  if (!columnOrder) return filteredExtensions;

  filteredExtensions = filteredExtensions.sort((a, b) => {
    return a[columnOrder] < b[columnOrder] ? -order : order
  })

  const lastPostIndex = currentPage * postsPerPage //currentPAge = 2 e postPerPage = 10 ---> 20
  const firstPostIndex = lastPostIndex - postsPerPage //lastPostIndex = 20 e postPerPage = 10 ---> 10
  const currentPosts = filteredExtensions.slice(firstPostIndex, lastPostIndex)
  

  return (
    <div className='container'>
      <h1>
        Ramais da {props.selectedUcm && props.selectedUcm.cidade + (props.selectedUcm.local ?`( ${props.selectedUcm.local} )`: "")}
      </h1>
      <br />
      <div className='inputs-div'>
    
        <input 
          type="text" 
          placeholder="Pesquisar" 
          value={search}
          onChange={((e) => setSearch(e.target.value))}
          className="search" />
          <label>
          Mostrando: 
        </label>
        <select className='input-selection' onChange={e => setPostsPerPage(e.target.value)}>
          <option value='10'>10</option>
          <option value='20'>20</option>
          <option value='30'>30</option>
          <option value='40'>40</option>
          <option value='50'>50</option>
        </select>
      </div>
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead className='thead-dark'>
            <tr>
              <th 
                className='filter'
                onClick={() => handleOrder('extension')}
              > 
                Ramal {order === 1 ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />} 
              </th>
              <th onClick={() => handleOrder('fullname')}>Nome</th>
              <th onClick={() => handleOrder('fullname')}>Setor</th>
            </tr>
        </thead>
        <tbody className='thead-light'>
          {currentPosts.map((extension, index) => (
            <tr key={index} className={ evenOdd(index) }>
                <td>{extension.extension}</td>
                <td>{extension.fullname?.split('-')[0]? extension.fullname?.split('-')[0] : extension.fullname}</td>
                <td>{extension.fullname?.split('-')[1]? extension.fullname?.split('-')[1] : extension.fullname}</td>
                {/* console.log(extension.fullname?.split(/-|–/g).join().replace(",","").replace(/,/g,"-")) */}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination 
        postsPerPage={postsPerPage} 
        totalPosts={filteredExtensions.length} 
        currentPage={currentPage} 
        setCurrentPage={setCurrentPage}
      />
      <ButtonsFile
        title ="Ramais"
        headers={["Ramal","Nome","Setor"]}
        data={filteredExtensions}  
      />
      <div className='back-button'>
        <button onClick={() => props.changeScreen('infos')}>Voltar</button>
      </div>
    </div>
  )
}

export default Extensions