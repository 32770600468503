import React , { useState ,useEffect,useContext } from 'react';
import {InfosContext} from '../../../contexts/InfosContext';
function Options(props) {

    const infosContext = useContext(InfosContext);

    const {periodo, setPeriodo} = infosContext;

    const [options, setOptions] = useState({        
        start:'',
        end:'',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOptions(options => ({ ...options, [name]: value }));
    }


    const clear = () => {
        setOptions({
            start: '',
            end: ''
        });
        props.clear()
    }
    const handleClick = (e) => {
        e.preventDefault();
        props.find(options);
    }

    useEffect(() => {
        if(periodo.start !== '' && periodo.end !== ''){
            setOptions(periodo);
        }
    }, []);

   
    const zeroAssert = (num) => {
        if(num < 10){
            return `0${num}`
        }
        return num
    }
    const formatDate = (date) => {
        let year = date.getFullYear();
        let month = zeroAssert(date.getMonth()+1);
        let day = zeroAssert(date.getDate());
        return `${year}-${month}-${day}`
    }
    return (
        <div className='options'>
            <div className='options-title'>
                <h2>Intervalo de datas</h2>
            </div>
            <div className='options-inputs'>
                <div className='row-date'>
                    <label htmlFor="start">Início:</label>
                    <input type="date" id="start" name="start" value={options.start} onChange={handleChange}/>
                </div>
                <div className='row-date'>
                    <label htmlFor="end">Fim:</label>
                    <input type="date" id="end" name="end" value={options.end} onChange={handleChange}/>
                </div>
                <br />
            </div>
            <div className='buttons-options'>
                <button className='btn-opt' onClick={clear }>Limpar</button>
                <button className='btn-opt' onClick={handleClick}>Aplicar</button>
            </div>
        </div>
    );
}

export default Options;