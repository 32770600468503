import React, { useContext } from 'react';
import { useLocation, Navigate } from "react-router-dom";
import { UserContext } from '../contexts/UserContext';
import { UCMSProvider } from '../contexts/UCMSContext'
import {InfosProvider} from '../contexts/InfosContext'
function Authenticator({children}) {

  const context = useContext(UserContext);
  let authed = context.token;

  return (
    <>
      {authed ? <>

      <UCMSProvider>
        <InfosProvider>
          {children}
        </InfosProvider>
      </UCMSProvider>

      </> : <>
          <Navigate to="/login" />
      </>}

    </>
  )
}

export default Authenticator