import env from "react-dotenv";

import axios from 'axios';

let baseURL = ""
if(!env || !env.REACT_APP_ENV_APIVOX){
  baseURL = "https://vox-ucms-api.voxcity.com.br/";
}
else{
  baseURL = env.REACT_APP_ENV_APIVOX;
}
const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default api;