import React from 'react';
import * as C from './styles';
import {FaPhone} from 'react-icons/fa';

function Card(props) {
  return (
    <C.Container>
      <C.CardTitle>{props.title}</C.CardTitle>
      <C.CardContent>{props.info}</C.CardContent>
      <C.CardButton onClick={props.handleClick}>Ver mais</C.CardButton>
      {/* <C.CardIcon>
        <FaPhone/>
      </C.CardIcon> */}
    </C.Container>
  )
}

export default Card