const treatZero = (value) => value < 10 ? `0${value}` : value;

const negativeToPositive = (value) => value < 0 ? value * -1 : value;

export function secondsToFullTime(seconds) {
    const hours = Math.floor(negativeToPositive(seconds) / 3600);
    const minutes = Math.floor((negativeToPositive(seconds) % 3600) / 60);
    const secs = Math.floor(negativeToPositive(seconds) % 60);
    return `${treatZero(hours)}:${treatZero(minutes)}:${treatZero(secs)}`;
}

export function formatDate( date ){
    if(date === null) return 'Não selecionado';
    let newDate = date.split('-')
    return newDate[2] + '/' + newDate[1] + '/' + newDate[0];
}

export function setPeriodoPdf(periodo){
    if(periodo.start && periodo.end){
        return formatDate(periodo.start) + ' - ' + formatDate(periodo.end);
    }else{
        return 'Não selecionado';
    }
}
export function positive(value){
    return value < 0 ? value * -1 : value;
}