import { motion } from "framer-motion";
import '../styles.css'
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import {useParams} from 'react-router-dom'
import { UserContext } from '../../../contexts/UserContext'
import api from '../../../services/api'
function Recover(props) {

  const navigate = useNavigate();
  const { authenticated, handleLogin } = useContext(UserContext)
  const [confirmPassword, setConfirmPassword] = useState('')
  const {token} = useParams()
  
  const [formData, setFormData] = useState({
    hash: token,
    conta: '',
    senha: '',
  })
  const [loading, setLoading] = useState(false)
   
  function handleClick(e) {
    e.preventDefault()
    setLoading(true)
    if(!checkedPass()) {
        setLoading(false)
        return alert('As senhas não conferem')
    }
    api.post('/login/changeForgotPassword', formData)
    .then((response) => {
      
      alert(response.data.error? response.data.error : response.data.message)
      setLoading(false)
      navigate('/login')      
    })
    .catch((error) => {
      setLoading(false)
      console.log(error)
    })
  }

function checkedPass() {
    if(formData.senha === confirmPassword) {
        return true
    }
    return false
}

  return (

    <div className='login-container'>
      <div className="login-form">
        <div className='login-header'>
          <img src="/logo-branca.png" alt="logo" />
        </div>
        <h3  style={{
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: 'normal',
          margin: '1rem 0 0.5rem 0',
          
        }} >
          Criação de Senha
        </h3  >
        <div className='form-content'>
          <form>

            <label name='conta'>Conta</label>
            <input 
              name='conta'
              value={formData.conta}
              onChange={(e) =>
                setFormData({ ...formData, conta: e.target.value })
            }></input>

            <label name='senha'>Senha</label>
            <input 
              type='password' 
              name='email'
              value={formData.senha}
              onChange={(e) =>
                setFormData({ ...formData, senha: e.target.value })
            }></input>

            <label name='senha'>Confirme a senha</label>
            <input
                type='password'
                name='senha'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
            ></input>
            
            <button 
              className='next-btn'
              disabled={loading}
              onClick={handleClick}>{loading ? 'Carregando...' : 'Registrar'}</button>
            <button 
              className='previous-btn'
              onClick={() => {
                navigate('/login')
            }}>Voltar</button>
            
          </form>
        </div>
        </div>
        </div>
  )
}

export default Recover