import React from 'react';

import {MdRemoveCircleOutline} from 'react-icons/md';

import {useState,useEffect} from 'react';

function EditTable(props) {
    const [ramaisOnViewtOnView, setListOnView] = useState(null);
    
    const ramalOnSearch = (e)=>{
        let newList = props.list.ramais.filter(ramal => ramal.extension.includes(e.target.value));
        setListOnView(newList);
    }

    useEffect(() => {
        setListOnView(props.list.ramais);
    }, [props.list]);
    return (
        <div className='meu-link-de-ramais'>
            <div className='meu-link-de-ramais-header'>
                <div className='meu-link-de-ramais-list-name'>
                    <label>Nome da Lista:</label>
                    <input type='text' value={props.list.nome} onChange={(e)=>props.setList({...props.list,nome:e.target.value}) }/>
                </div>
                <input type='text' onChange={ramalOnSearch} placeholder='Pesquisar Ramal'/>
                <span className='total-ramais'>{ramaisOnViewtOnView?.length} Ramais</span>
            </div>
                <div className='meu-link-de-ramais-table'>
                <table>
                    <thead>
                        <tr>
                            <th style={{width:"5%"}}>Ramal</th>
                            <th style={{width:"30%"}}>Nome</th>
                            <th style={{width:"30%"}}>Setor</th>
                            <th style={{width:"30%"}}>UCM</th>
                            <th style={{width:"5%"}}>
                                <input type='checkbox' checked={props.selectedToRemove.length === ramaisOnViewtOnView?.length} onChange={e => {
                                    if(e.target.checked){
                                        props.setSelectedToRemove([...ramaisOnViewtOnView]);
                                    } else {
                                        props.setSelectedToRemove([]);
                                    }
                                }
                                }/>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                {
                    props.list && ramaisOnViewtOnView?.map((ext,index) => {     
                        console.log(ext)     
                        let estaUcm =props.ucms?.find(ucm=>ucm._id ===ext.ucm);       
                        console.log(estaUcm);
                        return (
                            <tr key={index} >
                                <td>{ext.extension}</td>
                                <td>
                                    <input className='input-table' type="text" value={ext.name} onChange={(e)=>{
                                        let newList = {...props.list};
                                        newList.ramais[index].name = e.target.value;
                                        props.setList(newList);
                                    }}/>
                                </td>
                                <td>
                                    <input className='input-table' type="text" value={ext.setor} onChange={(e)=>{
                                        let newList = {...props.list};
                                        newList.ramais[index].setor = e.target.value;
                                        props.setList(newList);
                                    }}/>
                                </td>
                                <td>{estaUcm?.cidade +'-'+ estaUcm?.local}</td>
                                <td>
                                    <input type='checkbox' checked={props.selectedToRemove.find(r=>r.extension === ext.extension && r.ucm === ext.ucm)} onChange={e => {
                                        if(e.target.checked){
                                            props.setSelectedToRemove([...props.selectedToRemove,{...ext}]);
                                        } else {
                                            let newSelected = [...props.selectedToRemove];
                                            newSelected = newSelected.map(r => {
                                                if(r.extension === ext.extension && r.ucm === ext.ucm){
                                                    return null;
                                                } else return r;
                                            });
                                            newSelected = newSelected.filter(r => r !== null);
                                            props.setSelectedToRemove(newSelected);
                                        }
                                    }
                                    }/>

                                </td>
                            </tr>
                        )
                    })  
                }
            </tbody>
                </table>
            </div>
        </div>
    );
}

export default EditTable;