import { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {io} from 'socket.io-client'
import api from '../services/api';

 const UserContext = createContext()

 function UserProvider({ children }) {
   
  const navigate = useNavigate()

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [pref, setPref] = useState(null);
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState(null);


  async function handleLogin(user){
    setLoading(true)
    const resp = await api.post("/login/voxcount", user).catch(err=>{
      alert("Não foi possível fazer o login, tente novamente mais tarde")
    })
    if(resp && resp.data.error){
      alert(resp.data.error)  
    }else if(resp){
        let usuario = resp.data.user
        let token = resp.data.accessToken
        let refreshToken = resp.data.refreshToken
        let pref = resp.data.pref
        api.defaults.headers['authorization'] = `Bearer ${token}`        
        localStorage.setItem('refreshToken', refreshToken)
        setToken(token)
        setUser(usuario)
        setPref(pref)
        localStorage.setItem('token', refreshToken)
        navigate('/dashboard')
      }
    setLoading(false)
  }

  function initSocket() { 
    if(socket){
      socket.emit("online")   
      socket.on('lista edited', (list) => {
        let newpref = {...pref}
        newpref.services.voxcount.linkDeRamais = newpref.services.voxcount.linkDeRamais.map(l=>{
          if(l._id === list._id){
            return list
          }else{
            return l
          }
        })
      })
    
      socket.on('lista added', (list) => {
        let newpref = {...pref}
        newpref.services.voxcount.linkDeRamais.push(list)
        setPref(newpref)
      })

      socket.on('lista deleted', (list) => {
        let newpref = {...pref}
        newpref.services.voxcount.linkDeRamais = newpref.services.voxcount.linkDeRamais.filter(l=>l._id !== list._id)
        setPref(newpref)
      })
    }
  }

  function socketsOff(){
    socket.off('lista edited')
    socket.off('lista added')
    socket.off('lista deleted')

  }
  useEffect(()=>{
    const newToken = localStorage.getItem('token')
    if(newToken){
      // api.post('/login/refreshToken', {token:newToken}).then(resp=>{
      //   let usuario = resp.data.user
      //   let token = resp.data.accessToken
      //   let refreshToken = resp.data.refreshToken
      //   let pref = resp.data.pref
      //   api.defaults.headers['authorization'] = `Bearer ${token}`
      //   setUser(usuario)
      //   setPref(pref)
      //   setToken(token)
      //   localStorage.setItem('token', refreshToken)
      //   navigate('/dashboard')
      // }).catch(err=>{
      //   console.log(err)
      // })
    }
  },[])



  useEffect(() => {
      if(token){
          const newSocket = io.connect("wss://vox-ucms-api.voxcity.com.br/", {
            // const newSocket = io.connect("http://localhost:5000", {
              query:{
                  token:token
              }
          })
          setSocket(newSocket);  
                  
      }  
    return () => {
    }
  }, [token,setSocket])

useEffect(() => {
      if(socket){
          initSocket()
      }
      return () => {
          if(socket){
              socketsOff()
          }
      }
  }, [socket])
  return (
    <UserContext.Provider value={{token, handleLogin, user, setUser,pref,loading}}>
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
