import { motion } from "framer-motion";
import './styles.css'
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext'
import {Link} from 'react-router-dom';


function MailPassword({page, setPage, formData, setFormData, x, setX }) {

  const navigate = useNavigate();
  const { authenticated, handleLogin,loading } = useContext(UserContext)
  
    
  return (

      <motion.div 
      className='login-form'
      initial={{ x: x }}
      transition={{ duration: 1 }}
      animate={{ x: 0 }}>
        <div className='login-header'>
          <img src="/logo-branca.png" alt="logo" />
        </div>
        <div className='form-content'>
          <form>
            <label name='email'>E-mail</label>
            <input 
            type='text' 
            name='email'
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }></input>
            <label name='senha'>Senha</label>
            <input 
            type='password' 
            name='senha'
            value={formData.senha}
            onChange={(e) =>
              setFormData({ ...formData, senha: e.target.value })
            }></input>
            <button 
            className='next-btn'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault()
              handleLogin(formData)
              setX(1000);
            }}
            >{loading ? 'Carregando...' : 'Entrar'}</button>
            <button 
            className='previous-btn'
            onClick={() => {
              setPage(page - 1);
              setX(-1000);
            }}>Voltar</button>
            <Link to='/recover' className='forgot-password'>Esqueci minha senha</Link>
          </form>
        </div>
      </motion.div>

  )
}

export default MailPassword