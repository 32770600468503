import React, { useMemo, useState } from 'react'
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import '../queues/styles.css'

const OutboundsOnView = (props) => {
  console.log(props.selectedUcm.outbounds)
  
  const [order, setOrder] = useState(1)
  const [columnOrder, setColumnOrder] = useState('sequence')
  const [search, setSearch] = useState('')
  
  const evenOdd = (index) => {
    if (index % 2 === 0) {
        return 'even'
    } else {
        return 'odd'
    }
  }

  let filteredOutbounds = useMemo(() => {
    const lowerSearch = search.toLowerCase().trim()
    return props.selectedUcm.outbounds.filter((outbound) => lowerSearch === '' ? outbound 
      : outbound.sequence.toString().toLowerCase().includes(lowerSearch) 
      ||  outbound.outbound_rt_name.toLowerCase().includes(lowerSearch)
      ||  outbound.pattern.toLowerCase().includes(lowerSearch)
      ||  outbound.out_of_service.toLowerCase().includes(lowerSearch)
      ||  outbound.default_trunk_index.toString().toLowerCase().includes(lowerSearch)
    ) 
  }, [search, props.selectedUcm.outbounds])

  const handleOrder = (fieldName) => {
    setOrder(-order)
    setColumnOrder(fieldName)
  }

  if (!columnOrder) return filteredOutbounds;

  filteredOutbounds = filteredOutbounds.sort((a, b) => {
    return a[columnOrder] < b[columnOrder] ? -order : order
  })

  return (
    <div className='container'>
      <h1>
        Rotas de Saída - {props.selectedUcm && props.selectedUcm.cidade + (props.selectedUcm.local ?`( ${props.selectedUcm.local} )`: "")}
      </h1>
      <br />
      
      <div className='inputs-div'>
        <input 
          id="#search-queues" 
          type="text" 
          placeholder="Pesquisar" 
          value={search}
          onChange={((e) => setSearch(e.target.value))}
          className="search" 
        />
        <label>
          Mostrando: 
        </label>
        <select className='input-selection' onChange={e => setPostsPerPage(e.target.value)}>
          <option value='10'>10</option>
          <option value='20'>20</option>
          <option value='30'>30</option>
          <option value='40'>40</option>
          <option value='50'>50</option>
        </select>
      </div>
      <div style={{overflowX: 'auto'}}>
        <table className='table table-striped' aria-labelledby="tabelLabel">
          <thead className='thead-dark'>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th className='border' colspan='3'> Default </th>
              <th className='border' colspan='3'> Fail Over </th>
            </tr>
            <tr>
              <th 
                className='filter'
                onClick={() => handleOrder('sequence')}
              > 
                Sequência {order === 1 ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />} 
              </th>
              <th onClick={() => handleOrder('outbound_rt_name')}>Nome</th>
              <th onClick={() => handleOrder('pattern')}>Dialplan</th>
              <th onClick={() => handleOrder('out_of_service')}>Desativado</th>
              <th className='border' onClick={() => handleOrder('default_trunk_index')}>Tronco</th>
              <th onClick={() => handleOrder('strip')}>Remover</th>
              <th className='border' onClick={() => handleOrder('prepend')}>Prefixo</th>
              <th className='border' onClick={() => handleOrder('failover_a_trunk_index')}>Desativado</th>
              <th onClick={() => handleOrder('failover_a_strip')}>Tronco</th>
              <th className='border' onClick={() => handleOrder('failover_a_prepend')}>Remover</th>
            </tr>
          </thead>
          <tbody className='thead-light'>
            {filteredOutbounds.map((outbound, index) => (
              <tr key={index} className={ evenOdd(index) }>
                  <td>{outbound.sequence}</td>
                  <td>{outbound.outbound_rt_name}</td>
                  <td>{outbound.pattern}</td>
                  <td>{outbound.out_of_service}</td>
                  <td>{outbound.default_trunk_index}</td>
                  <td>{outbound.strip ? outbound.strip : 'Nenhum'}</td>
                  <td>{outbound.prepend ? outbound.prepend : 'Nenhum'}</td>
                  <td>{outbound.failover_a_trunk_index ? outbound.failover_a_trunk_index : 'Nenhum'}</td>
                  <td>{outbound.failover_a_strip ? outbound.failover_a_strip : 'Nenhum'}</td>
                  <td>{outbound.failover_a_prepend ? outbound.failover_a_prepend : 'Nenhum'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='back-button'>
        <button onClick={() => props.changeScreen('infos')}>Voltar</button>
      </div>
    </div>
  )
}

export default OutboundsOnView


/*  ||  outbound.strip.toLowerCase()?.includes(lowerSearch)
|| outbound.prepend.toLowerCase()?.includes(lowerSearch)
||  outbound.failover_a_trunk_index.toString().toLowerCase()?.includes(lowerSearch)
||  outbound.failover_a_strip.toLowerCase()?.includes(lowerSearch)
|| outbound.failover_a_prepend?.toLowerCase()?.includes(lowerSearch) */