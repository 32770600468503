import React from 'react'
import { createContext, useEffect, useState , useContext,useMemo} from 'react';
import { UCMSContext } from './UCMSContext';
import api from '../services/api';
const InfosContext = createContext();

function InfosProvider({ children }) {

  const [loading, setLoading] = useState(0)
  const [loadAgentsStats, setLoadAgentsStats] = useState(0)
  const [loadAgentsDetails, setLoadAgentsDetails] = useState(0)
  const [queueStats, setQueueStats] = useState([]);
  const [agentsDetails, setAgentsDetails] = useState([]);
  const [agentsStats, setAgentsStats] = useState([]);
  const [periodo, setPeriodo] = useState({
    start: '',
    end: ''
  });
  const ucmsContext = useContext(UCMSContext);

  const [search, setSearch] = useState('');



  function parsePercent(value, total){
    return Math.round((value/total)*100)
  }

  async function getQueuesStats(idUCM){
    setLoading(1)
    for(let i = 0; i < ucmsContext.ucmSelected.queues?.length; i++){
      if(ucmsContext.ucmSelected._id !== idUCM) return;
      setLoading(parsePercent(i, ucmsContext.ucmSelected.queues?.length))
      let queue = ucmsContext.ucmSelected.queues[i];
      let resp = await api.post("/queues/stats", {ucm: ucmsContext.ucmSelected._id,queue:queue.extension}).catch(err=>console.log(err))
      let stats = resp.data;
      setQueueStats(queueStats => [...queueStats, stats]);
    }
    setLoading(100)
  }
  async function getAgentsDetails(idUCM){
    setLoadAgentsDetails(1)
    let detailsArray = [];
    for(let i = 0; i < ucmsContext.ucmSelected.queues?.length; i++){
      if(ucmsContext.ucmSelected._id !== idUCM) return;
      let queue = ucmsContext.ucmSelected.queues[i];
      let members = queue.members;
      if(members){
        members = members.split(',');
        for(let j = 0; j < members.length; j++){
          setLoadAgentsDetails(parsePercent(i, ucmsContext.ucmSelected.queues?.length))
          let response = await api.post("/agents/details", {ucm: ucmsContext.ucmSelected._id, queue: queue.extension, members: members}).catch(err=>console.log(err))
          let stats = response.data;
          if(stats.error){ 

          }
          else{
            detailsArray.push(stats);
          }

        }
      }
    }
    setAgentsDetails(detailsArray);
    setLoadAgentsDetails(100)    
  }
  async function getAgentsStats(idUCM){
    setLoadAgentsStats(1)
    let statArray = [];
    for(let i = 0; i < ucmsContext.ucmSelected.queues?.length; i++){
      if(ucmsContext.ucmSelected._id !== idUCM) return 
      let queue = ucmsContext.ucmSelected.queues[i];
      let members = queue.members;

      if(members){
        members = members.split(',');
        for(let j = 0; j < members.length; j++){
          setLoadAgentsStats(parsePercent(i, ucmsContext.ucmSelected.queues?.length))
          let response = await api.post("/agents/stats", {ucm: ucmsContext.ucmSelected._id, queue: queue.extension, members: members}).catch(err=>console.log(err))
          let stats = response.data;
          if(stats.error){ 

          }
          else{
            statArray.push(stats)
          }
        }
      }
   }
    setAgentsStats(statArray);  
    setLoadAgentsStats(100)
  }
  useEffect(() => {
    if(ucmsContext.ucmSelected){
      if(ucmsContext.ucmSelected){
        cleanDataAndReqs();
        getQueuesStats(ucmsContext.ucmSelected._id.toString());
        getAgentsStats(ucmsContext.ucmSelected._id.toString());
        getAgentsDetails(ucmsContext.ucmSelected._id.toString());
        
      }
    }
  }, [ucmsContext.ucmSelected])



  function cleanDataAndReqs(){
    setQueueStats([]);
    setAgentsDetails([]);
    setAgentsStats([]);
  }

 
  return (
    <InfosContext.Provider value={{ queueStats, loading,agentsDetails,agentsStats,periodo,setPeriodo,loadAgentsStats,loadAgentsDetails  , search, setSearch }}>
        {children}
    </InfosContext.Provider>
  )
}

export {InfosContext, InfosProvider }


 // useEffect(() => {
  //   if(periodo.start && periodo.end){
  //     filterQueueStatsByPeriod();
  //     filterAgentsStatsByPeriod();
      
  //   }
  // }, [queueStats, agentsDetails, agentsStats])

  // function filterQueueStatsByPeriod(){
    
  // }
