import React from 'react';
import { Doughnut,Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto'

function GraficoSimples(props) {
    let legend = props.noLegend? false : true;
    let datasets = [];
    let data = {}
    let size = props.size;
    let height = "300px";

  
    if(size ==="small"){
        height = craeteHeightFromWindowSize()
    }

    if(!props.atendidas && !props.abandonadas){
        //full yellow empty graph
        datasets= [{
            label: '# of Votes',
            data: [ 1,0],
            backgroundColor: [
                '#8ecae6',
                "rgba(0,0,0,0)"
            ],
            borderColor: [
                '#8ecae6',
                "rgba(0,0,0,0)"
                
            ],

            borderWidth: 1,
            // hoverOffset: 4
        }]
        data = {labels: [ 'Sem Chamadas',"neste Período" ],
            datasets,
        }
        
    }
    else{
        datasets =  [{
            label: '# of Votes',
            data: [ props.abandonadas,props.atendidas],
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',  
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
            // hoverOffset: 4
        }]
        data = {
            labels: [ 'Abandonadas','Atendidas'],
            datasets,
        }
    }
    return (
                    <Pie
                        id={props.id}
                        data={data}
                        style={{height,}}
                        
                        responsive={true}
                        options={{
                            plugins: {
                                legend: {
                                    display:legend,                        
                                    labels: {
                                        sort:(a,b) => b.text.localeCompare(a.text),
                                        font : {
                                            size: 22,
                                        }
                                    }
                                },   
                                                     
                            },
                            maintainAspectRatio: false,
                        
                        }}
                />
    );
}

export default GraficoSimples;


function craeteHeightFromWindowSize(){
    let height = window.innerHeight;
    height = (height * 0.3 ) 
    return height + "px";
}