import { styled } from "@mui/material";

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
  width: 20%;
  padding: 1rem;
  margin: 1rem;
  background: rgb(174 222 255 / 50%) ;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all .4s;
  height:250px;
`

export const CardTitle = styled('h2')`
  font-size: 1.8rem;
`

export const CardContent = styled('h3')`
  font-size: 1.8rem;
  font-weight: 400;
`

export const CardButton = styled('button')`
  border: none;
  text-decoration: none;
  cursor: pointer;
  color: white;
  background: rgb(0, 30, 60);
  padding: 1rem 2rem;
  border-radius: .5rem;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all .3s;
  width: 100%;
  
  font-size: 1rem;
  /* text-shadow: .1rem .1rem #333; */
  /* border-radius: 1rem; */
  box-shadow: .1rem .1rem #333;
`
export const CardIcon = styled('div')`
  position: relative;
  bottom: 62px;
  left: 35%;
  font-size: 3rem;
`

