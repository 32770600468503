import { motion } from "framer-motion";
import '../styles.css'
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { UserContext } from '../../../contexts/UserContext'
import api from '../../../services/api'
function Recover(props) {

  const navigate = useNavigate();
  const { authenticated, handleLogin } = useContext(UserContext)
  
  const [formData, setFormData] = useState({
    email: '',
    conta: '',
  })
  const [loading, setLoading] = useState(false)
  
  
  function handleClick(e) {
    e.preventDefault()
    setLoading(true)
    api.post('/login/forgotPassword', formData)
    .then((response) => {
      
      alert(response.data.error? response.data.error : response.data.message)
      setLoading(false)
      navigate('/login')      
    })
    .catch((error) => {
      setLoading(false)
      console.log(error)
    })
  }

  return (

    <div className='login-container'>
      <div className="login-form">
        <div className='login-header'>
          <img src="/logo-branca.png" alt="logo" />
        </div>
        <h3  style={{
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: 'normal',
          margin: '1rem 0 0.5rem 0',
          
        }} >
          Recuperação de senha
        </h3  >
        <div className='form-content'>
          <form>

            <label name='conta'>Conta</label>
            <input 
              name='conta'
              value={formData.conta}
              onChange={(e) =>
                setFormData({ ...formData, conta: e.target.value })
            }></input>

            <label name='email'>E-mail</label>
            <input 
              type='text' 
              name='email'
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
            }></input>
           
            <button 
              className='next-btn'
              disabled={loading}
              onClick={handleClick}>{loading ? 'Carregando...' : 'Enviar'}</button>
            <button 
              className='previous-btn'
              onClick={() => {
                navigate('/login')
            }}>Voltar</button>
            
          </form>
        </div>
        </div>
        </div>
  )
}

export default Recover